import { faClose, faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 useAddToken,
 useBuy,
 useBuyPresale,
 useConnect,
 useRefAddress,
} from "fuhi-web3ts";
import { useState } from "react";
import { ENV } from "../../environment";

export const Setting = () => {
 const { createInvite, referral } = useRefAddress();
 const { balance, balanceToken } = useBuyPresale();
 const { symbol } = useBuy();
 const { addToken, isSupport } = useAddToken();
 const { disconnect, isConnected, connect } = useConnect();
 if (!isConnected)
  return (
   <button onClick={connect}>
    <FontAwesomeIcon icon={faGear} />
   </button>
  );
 return (
  <>
   <OverlayTrigger
    overlay={
     <div className="text-white flex flex-col w-[240px] md:w-[420px]">
      <div>
       <div className="flex justify-between gap-4">
        <h4 className="text-lg font-bold mb-4 text-[#e3e3e3]">User settings</h4>
       </div>
       <div
        className="flex flex-col md:flex-row gap-4 cursor-pointer"
        onClick={createInvite}
       >
        <Box className="flex-1 w-full whitespace-nowrap">Create referral</Box>
        <Box className="truncate">{referral}</Box>
       </div>
      </div>
      <div>
       {isConnected && (
        <>
         <div className="text-[#c3c5cb] text-sm font-medium  my-4">
          Total Balance
         </div>
         <div className="flex flex-col space-y-4">
          <div className="flex space-x-2 items-center">
           <Box className="min-w-40">{balance}</Box>
           <span className="font-medium">{symbol}</span>
          </div>
          <div className="flex justify-between flex-col md:flex-row gap-4">
           <div className="flex space-x-2 items-center">
            <Box className="min-w-40">{balanceToken}</Box>
            <span className="font-medium">{ENV.tokenSymbol}</span>
           </div>
           {isSupport && (
            <Box onClick={addToken}>
             <button className="">Import token</button>
            </Box>
           )}
          </div>
          <div className="text-[#c3c5cb] text-sm font-medium  my-4">
           Your wallet
          </div>
          <Box onClick={disconnect} className="w-fit">
           <button>Disconnect</button>
          </Box>
         </div>
        </>
       )}
      </div>
     </div>
    }
   >
    <button>
     <FontAwesomeIcon icon={faGear} />
    </button>
   </OverlayTrigger>
  </>
 );
};
const Box = ({ onClick, className, children }) => {
 return (
  <div
   className={`rounded-lg bg-[#161b1e] px-3 py-2  hover:bg-[#4281ff] ${
    onClick ? "cursor-pointer" : ""
   } ${className ?? ""}`}
   onClick={onClick}
  >
   {children}
  </div>
 );
};

function OverlayTrigger({ overlay, children }) {
 const [show, setShow] = useState(false);

 const handleMouseEnter = () => setShow(true);
 const handleMouseLeave = () => setShow(false);

 return (
  <div>
   <div onClick={handleMouseEnter}>{children}</div>

   {show && (
    <Tooltip>
     <button className="absolute right-5 top-4" onClick={handleMouseLeave}>
      <FontAwesomeIcon icon={faClose} />
     </button>
     {overlay}
    </Tooltip>
   )}
  </div>
 );
}

function Tooltip({ children }) {
 return (
  <div className="z-[100]">
   <div className="absolute -translate-x-full translate-y-2 p-6 border border-neutral-600 rounded-md bg-black text-white w-fit ">
    {children}
   </div>
  </div>
 );
}
