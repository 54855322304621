import React from "react";
import Badge from "../../components/ui/Badge";
import Button from "../../components/ui/Button";

export default function Secured() {
 return (
  <section className="secured relative">
   <div className="container">
    <div className="absolute pointer-events-none inset-0 w-full h-full">
     <img
      src="/cdn/65b632600ee72a952f031c51_EGLD-Get-Section07.webp"
      loading="lazy"
      alt=""
      className="crystal-get is-7"
     />
     <img
      src="/cdn/65b632613037cbbc2a8b1814_EGLD-Get-Section06.webp"
      loading="lazy"
      alt=""
      className="crystal-get is-6"
     />
     <img
      src="/cdn/65b63262aef99f67e0fd0a2d_EGLD-Get-Section01.webp"
      loading="lazy"
      alt=""
      className="crystal-get"
     />
     <img
      src="/cdn/65b6326117c3ad76b124b6a6_EGLD-Get-Section02.webp"
      loading="lazy"
      alt=""
      className="crystal-get is-2"
     />
     <img
      src="/cdn/65b632624b8e99cd2d4acea1_EGLD-Get-Section03.webp"
      loading="lazy"
      alt=""
      className="crystal-get is-3"
     />
     <img
      src="/cdn/65b632610a4536d4fca3c491_EGLD-Get-Section04.webp"
      loading="lazy"
      alt=""
      className="crystal-get is-4"
     />
     <img
      src="/cdn/65b63262fc4c59f7f2b37243_EGLD-Get-Section05.webp"
      loading="lazy"
      alt=""
      className="crystal-get is-5"
     />
    </div>
    <div className="flex relative flex-col text-center items-center my-s200">
     <Badge className="btn outline-green">The ZAL Token</Badge>
     <div className="mt-s24">
      <h2 className="text-[3rem] lg:text-[5.25rem] font-medium">
       Secured by best-in-class technology
      </h2>
     </div>
     <div className="max-w-[480px] mt-s16">
      <div className="text-p_21 text-c-neutral-500 leading-normal">
       The native ZAL token enables access and use, providing security,
       enhancing growth and ensuring economic alignment for all stakeholders.
      </div>
     </div>
     <div className="flex max-sm:flex-col justify-center w-full items-stretch gap-s16 mt-s32">
      <Button url="/staking" className="font-semibold">
       Stake ZAL
       <div className="w-s20 h-s20 flex justify-center items-center">
        <svg
         width="100%"
         height="100%"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
        >
         <path
          d="M24 1.5C24 6.84375 20.0156 11.25 14.8594 11.9062C14.5312 9.42188 13.4531 7.17188 11.8125 5.39062C13.5938 2.20312 17.0625 0 21 0H22.5C23.2969 0 24 0.703125 24 1.5ZM0 4.5C0 3.70312 0.65625 3 1.5 3H3C8.76562 3 13.5 7.73438 13.5 13.5V15V22.5C13.5 23.3438 12.7969 24 12 24C11.1562 24 10.5 23.3438 10.5 22.5V15C4.6875 15 0 10.3125 0 4.5Z"
          fill="currentColor"
         />
        </svg>
       </div>
      </Button>
      <Button url="/egld" color="neutral" className="font-semibold">
       ZAL Token
       <div className="w-s20 h-s20 flex justify-center items-center">
        <svg
         width="100%"
         height="100%"
         viewBox="0 0 25 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
        >
         <path
          d="M23.6719 8.79688L17.2969 15.1719C16.8281 15.6406 16.125 15.6406 15.7031 15.1719C15.2344 14.75 15.2344 14.0469 15.7031 13.5781L20.1562 9.125H1.125C0.46875 9.125 0 8.65625 0 8C0 7.39062 0.46875 6.875 1.125 6.875H20.1562L15.7031 2.42188C15.2344 2 15.2344 1.29688 15.7031 0.828125C16.125 0.40625 16.8281 0.40625 17.2969 0.828125L23.6719 7.25C24.0938 7.67188 24.0938 8.375 23.6719 8.79688Z"
          fill="currentColor"
         />
        </svg>
       </div>
      </Button>
     </div>
    </div>
   </div>
  </section>
 );
}
