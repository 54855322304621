"use client";

import React, { useState } from "react";
import { ENV } from "../../environment";
import useScrollY from "../../hooks/useScrollY";
import { blockScroll, cn, unblockScroll } from "../../utils/helpers";
import { Link } from "../Link";
import HamburgerButton from "../ui/HamburgerButton";

export default function Header() {
 const menus = [
  {
   title: "Home",
   url: "/",
  },
  {
   title: "Get Started",
   url: "/get-started",
  },
  {
   title: "ZAL Token",
   url: "/zal-token",
  },
  {
   title: "Cross-chain",
   url: "/cross-chain",
  },
  {
   title: "Stake",
   url: "/staking",
  },
  {
   title: "Whitepaper",
   url: ENV.whitepaper,
  },
 ];

 const scrollY = useScrollY();
 const [toggle, setToggle] = useState(false);

 function handleToggle() {
  if (toggle) {
   unblockScroll();
  } else {
   blockScroll();
  }
  setToggle(!toggle);
 }

 return (
  <nav className="w-full fixed top-0 z-[200] py-s14 px-s12 max-md:pt-s14 max-md:pb-s14 max-lg:x-s12 max-lg:pb-s16 lg:py-s32 lg:pr-s64 lg:pl-s48">
   <div className="nav-overlay" />
   <div
    className={cn(
     "flex justify-between items-center w-full rounded-2xl py-s12 pr-s12 pl-s16 transition-colors"
    )}
    style={{
     backgroundColor: `rgba(0, 0, 0, ${Math.min(scrollY / 600, 1)})`,
    }}
   >
    <Link aria-label="Home" to="/" className="shrink-0 relative z-[2]">
     <img
      src="/logos/MultiversX-Logo.svg"
      alt="MultiversX"
      width={140}
      height={23}
      className="mvx_logo max-md:w-[111px]"
     />
    </Link>
    <div
     className={cn(
      "max-lg:pr-s16 max-lg:pb-s60 max-lg:pl-s24 lg:gap-2 max-lg:min-w-[16.7rem] max-sm:w-screen max-md:w-3/5 max-lg:pt-s75 max-md:pr-s16 max-md:pl-s24 max-md:pb-s24 max-lg:bg-c-black-900 max-lg:w-1/2 max-lg:h-screen max-lg:absolute max-lg:right-0 max-lg:top-0 max-lg:invisible max-lg:translate-x-full max-lg:opacity-50 max-lg:transition-all max-lg:duration-500 max-lg:flex",
      toggle && "max-lg:visible max-lg:translate-x-0 max-lg:opacity-100"
     )}
    >
     <div className="flex max-lg:overflow-y-auto max-lg:w-full max-lg:flex-col gap-s16 relative">
      {menus.map((menu, i) => (
       <Link
        key={i}
        to={menu.url}
        className="px-3 py-2 text-p_14 lg:text-c-neutral-400 hover:text-c-neutral-100 max-lg:justify-between max-lg:items-center max-lg:flex max-lg:py-s12 max-lg:text-2xl max-sm:pl-0 w-full max-sm:text-base"
       >
        <span className="text-nowrap">{menu.title}</span>
        <div className="lg:hidden">
         <div className="w-s16 h-s16">
          <svg
           width="100%"
           height="100%"
           viewBox="0 0 7 11"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
          >
           <path
            d="M6.08984 5.03516C6.33594 5.30859 6.33594 5.71875 6.08984 5.96484L1.71484 10.3398C1.44141 10.6133 1.03125 10.6133 0.785156 10.3398C0.511719 10.0938 0.511719 9.68359 0.785156 9.4375L4.69531 5.52734L0.785156 1.58984C0.511719 1.34375 0.511719 0.933594 0.785156 0.6875C1.03125 0.414062 1.44141 0.414062 1.6875 0.6875L6.08984 5.03516Z"
            fill="currentColor"
           />
          </svg>
         </div>
        </div>
       </Link>
      ))}
     </div>
    </div>
    <div role="navigation" className="p-s4 sm:p-s10 lg:hidden">
     <HamburgerButton onClick={handleToggle} toggle={toggle} />
    </div>
   </div>
  </nav>
 );
}
