import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

// To block scrolling
export const blockScroll = (addPadding = true) => {
  document.body.style.overflow = 'hidden';
  if (window.innerWidth <= 768) return;
  const scrollBarWidth =
    window.innerWidth - document.documentElement.clientWidth;
  if (addPadding) document.body.style.paddingRight = scrollBarWidth + 'px';
};

// To unblock scrolling
export const unblockScroll = () => {
  document.body.style.paddingRight = '';
  document.body.style.overflow = '';
};