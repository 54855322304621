import React from "react";
import FAQCard from "../cards/FAQCard";

export default function FAQList({ title, subtitle, items, className }) {
  return (
    <div
      itemScope="itemscope"
      itemType="https://schema.org/FAQPage"
      className="container py-s100"
    >
      <div className="max-sm:mb-[1.565em] mb-s50">
        <h2 className="text-p_26">{title}</h2>
        <div className="text-p_26 text-c-neutral-500">{subtitle}</div>
      </div>
      {items.map((item, index) => (
        <FAQCard key={index} item={item} className="" />
      ))}
    </div>
  );
}
