import MarqueeCommunity from "../../components/layout/MarqueeCommunity";
import EarnReward from "./EarnReward";
import Hero from "./Hero";
import Secured from "./Secured";
import VoteFuture from "./VoteFuture";

export default function StakingPage() {
 return (
  <>
   <Hero />
   <Secured />
   <EarnReward />
   <VoteFuture />
   <MarqueeCommunity />
  </>
 );
}
