import React from "react";
import { cn } from "../../utils/helpers";
import Button from "../ui/Button";

export default function FeatureCard({ item, className }) {
 return (
  <div
   className={cn(
    "feature-card relative overflow-hidden max-md:p-s24 max-lg:p-s32 p-s48 rounded-2xl bg-c-neutral-900",
    className
   )}
  >
   <div className="flex flex-col gap-s24 pt-s200 relative h-full z-[2]">
    <h3 className="text-[2rem] leading-[1.2]">{item.title}</h3>
    <div className="text-p_21 text-c-neutral-500 leading-normal">
     {item.description}
    </div>
    {item.link && (
     <Button url={item.link} color="neutral" className="mt-auto w-fit !px-s16">
      <div className="p_16">{item.linkText}</div>
      <div className="w-s24 h-s24 flex justify-center items-center">
       <svg
        width="100%"
        height="100%"
        viewBox="0 0 25 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
       >
        <path
         d="M23.7656 8.5625L17.0156 15.3125C16.7344 15.5938 16.2188 15.5938 15.9375 15.3125C15.6562 15.0312 15.6562 14.5156 15.9375 14.2344L21.4219 8.75H0.75C0.328125 8.75 0 8.42188 0 8C0 7.625 0.328125 7.25 0.75 7.25H21.4219L15.9375 1.8125C15.6562 1.53125 15.6562 1.01562 15.9375 0.734375C16.2188 0.453125 16.7344 0.453125 17.0156 0.734375L23.7656 7.48438C24.0469 7.76562 24.0469 8.28125 23.7656 8.5625Z"
         fill="currentColor"
        />
       </svg>
      </div>
     </Button>
    )}
    {item.links && (
     <div className="flex flex-col gap-s12 mt-auto">
      {item.links.map((link, index) => (
       <a
        key={index}
        href={link.href}
        target="_blank"
        className="text-c-teal-400 transition-colors hover:text-c-dark-tial flex items-center gap-s16"
        rel=" noopener noreferrer"
       >
        <div className="text-p_21">{link.text}</div>
        <div className="w-s14 h-s14 flex justify-center items-center">
         <svg
          width="100%"
          height="100%"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
         >
          <path
           d="M11 1V8C11 8.5625 10.5312 9 10 9C9.4375 9 9 8.5625 9 8V3.4375L2.6875 9.71875C2.3125 10.125 1.65625 10.125 1.28125 9.71875C0.875 9.34375 0.875 8.6875 1.28125 8.3125L7.5625 2H3C2.4375 2 2 1.5625 2 1C2 0.46875 2.4375 0 3 0H10C10.5312 0 11 0.46875 11 1Z"
           fill="currentColor"
          />
         </svg>
        </div>
       </a>
      ))}
     </div>
    )}
   </div>
   <div className="absolute left-0 right-0 flex flex-col items-center bottom-0 top-[-10%] sm:-top-12">
    <img src={item.img} alt="" className="sm:w-[90%]" />
    <div className="absolute bg-card inset-0 rounded-2xl" />
   </div>
  </div>
 );
}
