import React from "react";
import { LinkWhitepaper } from "../../components/LinkCollection";
import Badge from "../../components/ui/Badge";
import Button from "../../components/ui/Button";

export default function Hero() {
 return (
  <>
   <img
    src="/cdn/65b63262aef99f67e0fd0a2d_EGLD-Get-Section01.webp"
    alt=""
    className="max-w-none max-sm:top-[-1.7rem] w-[120%] absolute top-[-40rem] left-[-20%] rotate-[20deg] blur-[5px] z-[-1]"
   />
   <section className="hero relative overflow-hidden bg-c-black-900 rounded-[2.5rem]">
    <div className="bg-indv-egld-gradient sm:bg-indv-egld-gradient-sm absolute inset-0 z-[1]"></div>
    <div>
     <div className="absolute inset-0 z-0">
      <img
       src="/cdn/65b62f4cc3edfa5897b7378c_egld-hero.webp"
       alt=""
       className="indv-egld_img-hero"
      />
      <img
       src="/cdn/65ae96712ff8acf1736c9d59_glass%20x.webp"
       alt=""
       className="indv-egld_img-hero is-2"
      />
      <img
       src="/cdn/65ae7d59d35eab2001944199_Distorting%20Typography%404-1920x900-565%201.webp"
       alt=""
       className="indv-egld_img-hero is-3"
      />
     </div>
     <div className="container relative z-[2]">
      <div className="flex flex-col items-center text-center pt-s300 md:pt-s200 pb-s80">
       <Badge
        bgClip={false}
        textClass="opacity-100"
        className="font-medium px-3 !py-0.5 leading-[0.88rem]"
       >
        The ZAL Token
       </Badge>

       <div className="max-w-[80ch] mt-s16">
        <h1 className="text-[2.5rem] md:text-[3.5rem] tracking-[-.01em] font-medium">
         Discover a digital currency positioned for global adoption
        </h1>
       </div>
       <div className="mt-s16 max-w-[70ch]">
        <div className="text-p_21 text-c-neutral-500">
         ZAL Token is the native cryptocurrency of Zalva Network, an advanced
         Layer 2 solution designed to enhance scalability, security, and
         interoperability across blockchain ecosystems.
        </div>
       </div>
       <div className="flex flex-wrap gap-s16 mt-s40">
        <Button
         url="/staking"
         color="teal"
         className="max-sm:w-full font-medium !px-s20"
        >
         Stake ZAL
         <div className="w-s20 h-s20 flex justify-center items-center">
          <svg
           width="100%"
           height="100%"
           viewBox="0 0 24 24"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
          >
           <path
            d="M24 1.5C24 6.84375 20.0156 11.25 14.8594 11.9062C14.5312 9.42188 13.4531 7.17188 11.8125 5.39062C13.5938 2.20312 17.0625 0 21 0H22.5C23.2969 0 24 0.703125 24 1.5ZM0 4.5C0 3.70312 0.65625 3 1.5 3H3C8.76562 3 13.5 7.73438 13.5 13.5V15V22.5C13.5 23.3438 12.7969 24 12 24C11.1562 24 10.5 23.3438 10.5 22.5V15C4.6875 15 0 10.3125 0 4.5Z"
            fill="currentColor"
           />
          </svg>
         </div>
        </Button>
        <LinkWhitepaper>
         <Button color="neutral" className="max-sm:w-full font-medium !px-s16">
          Whitepaper
          <div className="w-s20 h-s20 flex justify-center items-center">
           <svg
            width="100%"
            height="100%"
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d="M23.6719 8.79688L17.2969 15.1719C16.8281 15.6406 16.125 15.6406 15.7031 15.1719C15.2344 14.75 15.2344 14.0469 15.7031 13.5781L20.1562 9.125H1.125C0.46875 9.125 0 8.65625 0 8C0 7.39062 0.46875 6.875 1.125 6.875H20.1562L15.7031 2.42188C15.2344 2 15.2344 1.29688 15.7031 0.828125C16.125 0.40625 16.8281 0.40625 17.2969 0.828125L23.6719 7.25C24.0938 7.67188 24.0938 8.375 23.6719 8.79688Z"
             fill="currentColor"
            />
           </svg>
          </div>
         </Button>
        </LinkWhitepaper>
       </div>
      </div>
     </div>
    </div>

    <div className="container relative z-[2] md:pt-s36 pb-s64">
     <div className="grid lg:grid-cols-12 gap-s8">
      <div className="lg:col-span-5 flex flex-col gap-s8 self-end items-end">
       <div className="bento_item w-full">
        <div className="bento-inner_vertical-24">
         <h2 className="text-c-neutral-500 font-medium">Total Staked</h2>
         <div className="ml-auto">
          <div className="text-[2rem] lg:text-[2.5rem] flex items-center">
           <span>$--</span>
           <span className="opacity-60">(--%)</span>
          </div>
         </div>
        </div>
       </div>
       <div className="flex gap-s8 w-full">
        <div className="bento_item max-md:w-1/2 w-full">
         <div className="bento-inner_vertical-24">
          <h2 className="text-c-neutral-500 font-medium">Data on Testnet</h2>
          <div className="ml-auto _100">
           <div className="text-[1.5rem] lg:text-[2rem] text-right">
            <span>100+ days</span>
           </div>
          </div>
         </div>
        </div>
        <div className="bento_item max-md:w-1/2 w-full">
         <div className="bento-inner_vertical-24">
          <h2 className="text-c-neutral-500 font-medium">Match rate</h2>
          <div className="ml-auto _100">
           <div className="text-[1.5rem] lg:text-[2rem] text-right">
            <span>100%</span>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div className="lg:col-span-4 flex md:flex-col gap-s8">
       <div className="bento_item max-md:w-1/2 w-full lg:w-fit">
        <div className="bento-inner_vertical-24">
         <h2 className="text-c-neutral-500 font-medium">
          Average Cost per Transaction
         </h2>
         <div className="ml-auto _100">
          <div className="text-[2rem] lg:text-[2.5rem] text-right">
           ~$0<span className="opacity-60">.005</span>
          </div>
         </div>
        </div>
       </div>
       <div className="bento_item max-md:w-1/2 w-full">
        <div className="bento-inner_vertical-24">
         <h2 className="text-c-neutral-500 font-medium">Transactions</h2>
         <div className="ml-auto _100">
          <div className="md:text-[2.5rem] lg:text-[3.5rem] text-right">
           <span>200,000+</span>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div className="bento_item lg:col-span-3 carbon-free h-fit mt-auto">
       <img
        src="/cdn/65ae99ad1dd1d64948bd8724_carbon-free_planet.webp"
        alt=""
        className="absolute max-w-none w-[120%] right-[-55%] z-0 rotate-[150deg]"
       />
       <div className="f_vertical-left relative">
        <h2 className="text-c-neutral-500 font-medium">
         Latency. Scalability. Security
        </h2>
        <div className="text-[2rem] md:text-[2.5rem] mt-s80 mb-s16">
         Zalva Network
        </div>
        <Button
         color="carbon-free"
         url="https://environment.multiversx.com/"
         className="w-fit px-s8 py-s6 rounded-[0.38rem] gap-s8"
         rel="noopener noreferrer"
        >
         <div className="text-p_14">Read Whitepaper</div>
         <div className="w-s10 h-s10 w-embed">
          <svg
           width="100%"
           height="100%"
           viewBox="0 0 8 8"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
          >
           <path
            d="M7.1875 0C7.49219 0 7.75 0.257812 7.75 0.5625V6.1875C7.75 6.51562 7.49219 6.75 7.1875 6.75C6.85938 6.75 6.625 6.51562 6.625 6.1875V1.92188L1.21094 7.33594C0.976562 7.57031 0.625 7.57031 0.414062 7.33594C0.179688 7.125 0.179688 6.77344 0.414062 6.5625L5.82812 1.14844H1.5625C1.23438 1.14844 1 0.890625 1 0.585938C1 0.257812 1.23438 0.0234375 1.5625 0.0234375H7.1875V0Z"
            fill="currentColor"
           />
          </svg>
         </div>
        </Button>
       </div>

       <div className="bento_curtain" />
      </div>
     </div>
    </div>
   </section>
  </>
 );
}
