import { faArrowDown, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 BuyPresaleProvider,
 useBuyPresale,
 useConnect,
 useRefAddress,
} from "fuhi-web3ts";
import Button from "../../components/ui/Button";
import { ENV } from "../../environment";
import { useBuyMethod } from "../../hooks/useBuyMethod";
import "./Buy.scss";
import { FormInput } from "./FormInput";
import { SelectMethod } from "./SelectMethod";
import { Setting } from "./Settings";
import { Clock } from "./clock";
export const PresalePage = () => {
 return (
  <BuyPresaleProvider>
   <Inner />
  </BuyPresaleProvider>
 );
};
const Inner = () => {
 const { balance, balanceToken, setFromValue, fromValue, toValue, buy } =
  useBuyPresale();
 const { connect, isConnected } = useConnect();
 const { createInvite, referral } = useRefAddress();
 const { logo, methodName } = useBuyMethod();
 const handleBuy = async () => {
  try {
   await buy();
  } catch (error) {}
 };
 return (
  <div className="pt-s300 md:pt-s200 pb-s100 buy-page font-sans max-w-[360px] md:max-w-[550px] mx-auto">
   <div className="rounded-2xl overflow-hidden">
    <img src="/images/presalebanner.png" alt="" />
   </div>
   <div className="mx-auto w-full mt-6  h-fit border-2 rounded-[30px] border-[#27272a] p-8">
    <div className="w-full flex items-center justify-between ">
     <p className="text-2xl font-bold ">Buy ZAL Token</p>

     <Setting />
    </div>
    <div className="py-4 flex justify-center">
     <Clock />
    </div>
    <p className="text-neutral-500 mt-2">
     One click to be amazed by Zalva speed!
    </p>
    <div className="w-full flex flex-col gap-6 mt-6">
     <FormInput
      placeholder={0.0}
      value={fromValue}
      onChange={setFromValue}
      tokenSymbol={methodName}
      logo={logo}
      balance={balance}
      inject={<SelectMethod />}
     />
     <div className="mx-auto bg-primary rounded-full w-5 h-5 flex items-center justify-center">
      <FontAwesomeIcon icon={faArrowDown} className="" />
     </div>
     <FormInput
      value={toValue}
      tokenSymbol={ENV.tokenSymbol}
      logo="/logo4wallet.png"
      balance={balanceToken}
     />
     <div className="w-full flex flex-col gap-2">
      {isConnected && (
       <div className="flex items-center justify-between px-2">
        <p className="text-sm satoshi text-muted-foreground truncate">
         Referral: {referral}{" "}
        </p>
        <p className="text-sm satoshi text-muted-foreground">
         <FontAwesomeIcon
          icon={faCopy}
          onClick={createInvite}
          className="cursor-pointer"
         />
        </p>
       </div>
      )}
     </div>
     {isConnected ? (
      <Button onClick={handleBuy}>Buy</Button>
     ) : (
      <Button onClick={connect}>Connect Wallet</Button>
     )}
    </div>
   </div>
  </div>
 );
};
