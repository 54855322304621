import React from "react";
import { LinkWhitepaper } from "../../components/LinkCollection";
import Button from "../../components/ui/Button";

export default function ExploreEcosystem() {
 return (
  <section className="container relative z-[-1] max-md:overflow-hidden">
   <div className="grid md:grid-cols-3 gap-s32">
    <div className="md:col-span-2 relative max-md:pt-s100">
     <div className="absolute max-md:-left-10 bottom-0 md:-top-40 md:right-0 w-[120%] md:w-[170%] lg:w-[130%] aspect-square">
      <img
       src="/cdn/65bab2763601368c818351cc_ecosystem-visual.webp"
       className="featured_img"
      />
     </div>
     <div className="w-full lg:hidden">
      <div className="pt-[100%]"></div>
     </div>
    </div>
    <div className="md:pr-s80 flex flex-col justify-center pb-s100 pt-0 lg:pt-s200 lg:pb-s400">
     <h2 className="text-[2rem]">Explore Zalva Ecosystem</h2>
     <p className="text-p_21 leading-normal text-c-neutral-500 mt-s24">
      The Decentralized World and Crypto Economy with Layer 2.
     </p>
     <LinkWhitepaper>
      <Button className="mt-s48 md:w-fit font-semibold">
       Whitepaper
       <div className="flex justify-center items-center w-s14 h-s14">
        <svg
         width="100%"
         height="100%"
         viewBox="0 0 22 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
        >
         <path
          d="M21.125 10.8438L12.875 18.7188C12.4062 19.1406 11.7031 19.1406 11.2812 18.6719C10.8594 18.2031 10.8594 17.5 11.3281 17.0781L17.5625 11.125H1.625C0.96875 11.125 0.5 10.6562 0.5 10C0.5 9.39062 0.96875 8.875 1.625 8.875H17.5625L11.3281 2.96875C10.8594 2.54688 10.8594 1.79688 11.2812 1.375C11.7031 0.90625 12.4531 0.90625 12.875 1.32812L21.125 9.20312C21.3594 9.4375 21.5 9.71875 21.5 10C21.5 10.3281 21.3594 10.6094 21.125 10.8438Z"
          fill="currentColor"
         />
        </svg>
       </div>
      </Button>
     </LinkWhitepaper>
    </div>
   </div>
  </section>
 );
}
