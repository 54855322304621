import React from "react";
import GetStartedCard from "../../../components/cards/GetStartedCard";

const items = [
 {
  title: "Setting Up Your Wallet",
  description:
   "To start interacting with Zalva Network, you will need a compatible cryptocurrency wallet. We recommend using popular wallets like MetaMask, Trust Wallet, or any wallet that supports Web3.",
  link: "/ecosystem/projects-category/wallets",
  linkText: "Get Essential Apps",
  img: "/cdn/65ae87d79cf6d11431aac53f_glass%20shield.webp",
 },
 {
  title: "Funding Your Wallet",
  description:
   "Before you can make transactions on Zalva Network, you'll need to fund your wallet with the appropriate assets.",
  link: "/egld",
  linkText: "EGLD Token",
  img: "/cdn/65b63262fc4c59f7f2b37243_EGLD-Get-Section05.webp",
 },
 {
  title: "Exploring dApps",
  description:
   "Zalva Network là nơi có hệ sinh thái các ứng dụng phi tập trung (dApp) đang phát triển được thiết kế để tận dụng tối đa nền tảng Lớp 2 tốc độ cao, chi phí thấp của chúng tôi.",
  link: "/ecosystem",
  linkText: "Discover MultiversX",
  img: "/cdn/65afb0008fe741c3b22d0ac2_The%20Marble%20Dyson%20Marvel%404-1024x1024%202.webp",
 },
 {
  title: "Developing on Zalva Network",
  description:
   "If you are a developer, Zalva Network provides a robust set of tools and documentation to help you build and deploy dApps on our Layer 2 solution.",
  link: "/ecosystem/projects",
  linkText: "Get Essential Apps",
  img: "/cdn/65afb0600f1a8e11e1934b78_The%20Marble%20Dyson%20Marvel%404-1024x1024%204.webp",
 },
];

export default function GetStarted() {
 return (
  <section className="section pt-s100 pb-s150">
   <div className="container">
    <div className="flex flex-col justify-center text-center items-center">
     <div className="max-w-[85ch]">
      <h2 className="text-[3rem] font-medium lg:text-[5.25rem]">
       Get started with Zalva Network and ZAL
      </h2>
     </div>
     <div className="max-w-[50ch] mt-s16">
      <div className="text-p_21 text-c-neutral-500 leading-normal">
       Whether you are a developer, a crypto enthusiast, or simply curious about
       how to enhance your blockchain experience.
      </div>
     </div>
    </div>
   </div>
   <div className="mt-s200">
    {items.map((item, index) => (
     <GetStartedCard key={index} index={index} item={item} className="" />
    ))}
   </div>
  </section>
 );
}
