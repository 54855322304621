"use client";

import React from "react";
import useInView from "../../hooks/useInView";
import { cn } from "../../utils/helpers";
import { LinkWhitepaper } from "../LinkCollection";
import Button from "../ui/Button";

export default function Planet() {
 const [ref, inView] = useInView();

 return (
  <section className="planet mt-[4.69rem]" ref={ref}>
   <div className="relative mt-[-4.69rem]">
    <div className="container z-0 bg-c-black-1000 !px-0 rounded-[2.5rem]">
     <div className="absolute z-0 inset-0 w-full h-full flex justify-center items-center">
      <div className="absolute w-[140%] max-sm:max-w-[30rem] sm:w-[80%] lg:w-[55%] xl:w-[45%] max-w-[59.6rem] flex justify-center items-center aspect-square">
       <div className="w-[135%] aspect-square absolute">
        <img
         src="/cdn/65afb67f4f83460397cfcf6f_Inner%20Orbit.svg"
         loading="lazy"
         alt=""
         className="img_square w-full"
        />
       </div>
       <div
        className={cn(
         "w-[135%] aspect-square absolute rotate-[-148deg] transition-transform ease-linear duration-[20s]",
         inView && "rotate-[155deg]"
        )}
       >
        <img
         src="/cdn/65afb893a7e0742568a02894_Outline%20Orbit.svg"
         loading="lazy"
         alt=""
         className="img_square w-full"
        />
       </div>
       <img
        src="/cdn/65afb67f4f83460397cfcf68_Planet.webp"
        alt=""
        className={cn(
         "mix-blend-multiply rotate-[-90deg] transition-transform ease-linear duration-[90s]",
         inView && "rotate-[90deg]"
        )}
       />
       <img
        src="/cdn/65afb67f4f83460397cfcf68_Planet.webp"
        loading="lazy"
        alt=""
        className={cn(
         "mix-blend-multiply mix-blend-normal opacity-25 absolute rotate-[-90deg] transition-transform ease-linear duration-[90s]",
         inView && "rotate-[90deg]"
        )}
       />
       <div className="absolute rounded-full z-[-1] opacity-90 bg-c-green blur-[3em] w-full h-full" />
       <div className="absolute rounded-full z-[-2] bg-c-green blur-[10em] w-full h-full" />
      </div>
     </div>
     <div className="relative flex flex-col text-center justify-center items-center gap-s24 py-s200">
      <div className="max-w-[60ch]">
       <h2 className="text-[3rem] lg:text-[5.25rem] font-medium">
        Efficiency Meets Security on Layer 2
       </h2>
      </div>
      <div className="max-w-[60ch]">
       <div className="max-lg:px-6 text-p_21 leading-normal">
        Zalva Network is more than just a blockchain scaling solution; it is the
        perfect combination of superior performance and optimal security.
       </div>
      </div>
      <LinkWhitepaper>
       <Button
        target="_blank"
        color="carbon-free"
        className="px-s24 py-s12"
        rel="noopener noreferrer"
       >
        <span className="text-p_18 font-medium text-c-black-900">
         Read Whitepaper
        </span>
        <div className="w-s24 h-s24 flex justify-center items-center">
         <svg
          width="100%"
          height="100%"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
         >
          <path
           d="M12.8516 6.62891L8.47656 11.0039C8.14844 11.3594 7.57422 11.3594 7.24609 11.0039C6.89062 10.6758 6.89062 10.1016 7.24609 9.77344L10.1172 6.875H1.75C1.25781 6.875 0.875 6.49219 0.875 6C0.875 5.53516 1.25781 5.125 1.75 5.125H10.1172L7.24609 2.25391C6.89062 1.92578 6.89062 1.35156 7.24609 1.02344C7.57422 0.667969 8.14844 0.667969 8.47656 1.02344L12.8516 5.39844C13.207 5.72656 13.207 6.30078 12.8516 6.62891Z"
           fill="currentColor"
          />
         </svg>
        </div>
       </Button>
      </LinkWhitepaper>
     </div>
    </div>
   </div>
  </section>
 );
}
