import MarqueeCommunity from "../../components/layout/MarqueeCommunity";
import Planet from "../../components/layout/Planet";
import Builders from "./Builders";
import ExploreEcosystem from "./ExploreEcosystem";
import FeaturedPartner from "./FeaturedPartner";
import Hero from "./Hero";
import Secured from "./Secured";
import SpaceID from "./SpaceID";

export default function Home() {
 return (
  <>
   <Hero />
   <Builders />
   <FeaturedPartner />
   <Secured />
   <Planet />
   <ExploreEcosystem />
   <MarqueeCommunity />
   <SpaceID />
  </>
 );
}
