import MarqueeCommunity from "../../components/layout/MarqueeCommunity";
import SideFeature from "../../components/layout/SideFeature";
import EasyToSetup from "./EasyToSetup";
import FAQs from "./FAQs";
import Hero from "./Hero";

const items = [
 {
  title: "Seamless Onboarding",
  description: (
   <>
    Zalva Network offers a smooth onboarding experience, allowing users to
    create and set up their accounts within minutes.
    <br />
    <br />
    We provide clear instructions and user support to ensure that even those new
    to blockchain technology can easily navigate the setup process.
   </>
  ),
  img: "/cdn/65afcf372c318b5144f17191_shield-check-img.webp",
  reverse: true,
  ctas: [],
 },
 {
  title: "Plug-and-Play Development",
  description: (
   <>
    For developers, Zalva provides an extensive set of tools and resources that
    simplify the process of dApp development and deployment.
   </>
  ),
  img: "/cdn/65afd333a87f4361707e7c88_flexibility-img.webp",
  reverse: false,
  ctas: [],
 },
 {
  title: "Cross-Platform Compatibility",
  description: (
   <>
    Zalva is compatible with a variety of devices and operating systems,
    ensuring users can access the network from their preferred platform, whether
    on desktop, tablet or mobile.
   </>
  ),
  img: "/cdn/65afd467978753f53c5cd64d_lock-15-degree.webp",
  reverse: true,
  ctas: [],
 },
];

export default function OnChain2FAPage() {
 return (
  <>
   <Hero />
   <EasyToSetup />
   {items.map((item, index) => (
    <SideFeature key={index} item={item} />
   ))}
   <FAQs />
   <MarqueeCommunity />
  </>
 );
}
