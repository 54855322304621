"use client";

import { useData } from "fuhi-web3ts";
import React from "react";
import Marquee from "react-fast-marquee";
import { LinkWhitepaper } from "../../components/LinkCollection";
import { News } from "./News";

const partners = [
 "/spaceid/ic-galxe.svg",
 "/spaceid/ic-trustwallet.svg",
 "/spaceid/ic-bscscan.svg",
 "/spaceid/ic-bitkeep.svg",
 "/spaceid/ic-mathwallet.svg",
 "/spaceid/ic-dodo.png",
 "/spaceid/ic-beefy.png",
 "/spaceid/ic-bovineverse.png",
 "/spaceid/ic-clover.svg",
 "/spaceid/ic-starrynift.png",
 "/spaceid/ic-cyberconnect.png",
 "/spaceid/ic-apeswap.png",
 "/spaceid/ic-tokenpocket.svg",
 "/spaceid/ic-safepal.svg",
];

export default function SpaceID() {
 const { data } = useData({ path: "/data/services.json", default: [] });
 //  useEffect(() => {
 //   AOS.init({
 //    once: true,
 //    duration: 1000,
 //    offset: 200,
 //    delay: 500,
 //   });
 //  }, []);

 return (
  <section className="space-id grow scrollable relative overflow-x-clip">
   <main className="min-h-[calc(100vh-64px)] flex flex-col items-stretch relative">
    <main className="w-full flex flex-col items-center mt-12">
     <div className="w-full relative flex flex-col items-center ">
      <div className="" />
      <div className="w-full bg-headerBg flex flex-col items-center px-[18px] lg:px-16">
       <div className="w-full flex flex-col items-center">
        <div
         data-aos="fade-up"
         className="font-bold text-lg lg:text-6xl section-title-bg"
        >
         Featured Secure Services
        </div>
        <div
         data-aos="fade-up"
         className="max-w-[1792px] w-full grid grid-cols-1 lg:grid-cols-3 gap-3 lg:gap-8 my-3 lg:my-8"
        >
         {data.map((item, idx) => (
          <FeaturedSecureService key={idx} {...item} />
         ))}
        </div>
       </div>
      </div>
      <div className="w-full flex items-center justify-center bg-headerBg">
       <div
        data-aos="fade-up"
        className="max-w-[1792px] w-[calc(100%-36px)] lg:w-[calc(100%-128px)] h-20 lg:h-40 mt-10 lg:mt-20 mx-[18px] lg:mx-16  bg-center bg-cover rounded-lg lg:rounded-3xl relative flex items-center justify-center"
        style={{ backgroundImage: "url('/spaceid/grant-program-bg.png')" }}
       >
        <div className="relative">
         <img
          alt="content"
          loading="lazy"
          width={960}
          height={160}
          decoding="async"
          data-nimg={1}
          className="hidden lg:block"
          style={{ color: "transparent" }}
          src="/spaceid/grant-program-content-desktop.png"
         />
         <img
          alt="content"
          loading="lazy"
          width={320}
          height={80}
          decoding="async"
          data-nimg={1}
          className="lg:hidden"
          style={{ color: "transparent" }}
          src="/spaceid/grant-program-content-mobile.png"
         />
        </div>
       </div>
      </div>
      <div className="w-full relative flex justify-center features-bg pb-20 lg:pb-20 px-[18px] lg:px-16">
       <div className="absolute left-0 right-0 top-0-0 h-[72px] bg-gradient-to-b from-[#152021] to-transparent" />
       <div className="max-w-[1792px] flex flex-col items-center gap-6 lg:gap-10 mt-20 lg:mt-40">
        <div className="w-full">
         <div
          data-aos="fade-up"
          className="flex flex-col items-center justify-center gap-[5px] lg:gap-[10px]"
         >
          <div className="text-lg lg:text-4xl 3xl:text-6xl text-center font-bold section-title-bg">
           Layer 2 and Web3 security all in one package
          </div>
          <div className="text-xs lg:text-lg 3xl:text-2xl text-center text-accent-content">
           Everything you need is here.
          </div>
         </div>
        </div>
        <div
         data-aos="fade-up"
         className="w-full grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-8 justify-center lg:justify-between items-center"
        >
         <div className="h-[164px] lg:h-[260px] flex flex-col items-start gap-[10px] lg:gap-5 bg-[#FFFFFF0D] border border-[#FFFFFF1A] rounded-[12px] lg:rounded-[18px] px-4 lg:px-7 3xl:px-10 pt-6 lg:pt-7 3xl:pt-10">
          <div className="block lg:hidden">
           <svg
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            color="#2980E8"
           >
            <g clipPath="url(#ic-explore_svg__a)" fill="currentColor">
             <path
              opacity="0.5"
              d="M14 28c-1.937 0-3.757-.368-5.46-1.103a14.128 14.128 0 0 1-4.445-2.992 14.129 14.129 0 0 1-2.992-4.445C.368 17.757 0 15.937 0 14c0-1.937.368-3.757 1.103-5.46a14.128 14.128 0 0 1 2.992-4.445A14.148 14.148 0 0 1 8.54 1.102C10.243.367 12.063 0 14 0c1.937 0 3.757.367 5.46 1.102a14.148 14.148 0 0 1 4.445 2.993 14.128 14.128 0 0 1 2.992 4.445C27.632 10.243 28 12.063 28 14c0 1.937-.368 3.757-1.103 5.46a14.128 14.128 0 0 1-2.992 4.445 14.128 14.128 0 0 1-4.445 2.992C17.757 27.632 15.937 28 14 28Zm0-2.8c3.103 0 5.746-1.09 7.928-3.272C24.11 19.746 25.2 17.103 25.2 14c0-3.103-1.09-5.746-3.272-7.928C19.746 3.89 17.103 2.8 14 2.8c-3.103 0-5.746 1.09-7.927 3.272C3.891 8.254 2.8 10.897 2.8 14c0 3.103 1.091 5.746 3.273 7.928C8.254 24.11 10.897 25.2 14 25.2Z"
             />
             <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="m7.595 21.105 8.75-4.095c.14-.07.268-.163.385-.28a1.48 1.48 0 0 0 .28-.385l4.095-8.75c.117-.233.087-.437-.088-.612s-.379-.205-.612-.088l-8.75 4.095c-.14.07-.268.163-.385.28-.117.117-.21.245-.28.385l-4.095 8.75c-.117.233-.087.437.088.612s.379.205.612.088ZM14 15.4a1.35 1.35 0 0 1-.997-.403A1.351 1.351 0 0 1 12.6 14c0-.397.134-.73.403-.998.268-.268.6-.402.997-.402s.73.134.998.402c.268.269.402.601.402.998s-.134.729-.402.997A1.356 1.356 0 0 1 14 15.4Z"
             />
            </g>
            <defs>
             <clipPath id="ic-explore_svg__a">
              <path fill="currentColor" d="M0 0h28v28H0z" />
             </clipPath>
            </defs>
           </svg>
          </div>
          <div className="hidden lg:block">
           <svg
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            color="#2980E8"
           >
            <g clipPath="url(#ic-explore_svg__a)" fill="currentColor">
             <path
              opacity="0.5"
              d="M14 28c-1.937 0-3.757-.368-5.46-1.103a14.128 14.128 0 0 1-4.445-2.992 14.129 14.129 0 0 1-2.992-4.445C.368 17.757 0 15.937 0 14c0-1.937.368-3.757 1.103-5.46a14.128 14.128 0 0 1 2.992-4.445A14.148 14.148 0 0 1 8.54 1.102C10.243.367 12.063 0 14 0c1.937 0 3.757.367 5.46 1.102a14.148 14.148 0 0 1 4.445 2.993 14.128 14.128 0 0 1 2.992 4.445C27.632 10.243 28 12.063 28 14c0 1.937-.368 3.757-1.103 5.46a14.128 14.128 0 0 1-2.992 4.445 14.128 14.128 0 0 1-4.445 2.992C17.757 27.632 15.937 28 14 28Zm0-2.8c3.103 0 5.746-1.09 7.928-3.272C24.11 19.746 25.2 17.103 25.2 14c0-3.103-1.09-5.746-3.272-7.928C19.746 3.89 17.103 2.8 14 2.8c-3.103 0-5.746 1.09-7.927 3.272C3.891 8.254 2.8 10.897 2.8 14c0 3.103 1.091 5.746 3.273 7.928C8.254 24.11 10.897 25.2 14 25.2Z"
             />
             <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="m7.595 21.105 8.75-4.095c.14-.07.268-.163.385-.28a1.48 1.48 0 0 0 .28-.385l4.095-8.75c.117-.233.087-.437-.088-.612s-.379-.205-.612-.088l-8.75 4.095c-.14.07-.268.163-.385.28-.117.117-.21.245-.28.385l-4.095 8.75c-.117.233-.087.437.088.612s.379.205.612.088ZM14 15.4a1.35 1.35 0 0 1-.997-.403A1.351 1.351 0 0 1 12.6 14c0-.397.134-.73.403-.998.268-.268.6-.402.997-.402s.73.134.998.402c.268.269.402.601.402.998s-.134.729-.402.997A1.356 1.356 0 0 1 14 15.4Z"
             />
            </g>
            <defs>
             <clipPath id="ic-explore_svg__a">
              <path fill="currentColor" d="M0 0h28v28H0z" />
             </clipPath>
            </defs>
           </svg>
          </div>
          <div className="flex flex-col gap-[4px] lg:gap-[10px]">
           <div className="text-white text-left font-bold text-lg lg:text-2xl">
            High Throughput
           </div>
           <div className="text-quarternary2 lg:text-white text-left text-xs lg:text-base leading-4 lg:leading-6">
            Ability to process thousands of transactions per second
           </div>
          </div>
         </div>
         <div className="h-[164px] lg:h-[260px] flex flex-col items-start gap-[10px] lg:gap-5 bg-[#FFFFFF0D] border border-[#FFFFFF1A] rounded-[12px] lg:rounded-[18px] px-4 lg:px-7 3xl:px-10 pt-6 lg:pt-7 3xl:pt-10">
          <div className="block lg:hidden">
           <svg
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            color="#E7BB41"
           >
            <path
             fillRule="evenodd"
             clipRule="evenodd"
             d="M21 26a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm3.6-6.2a1 1 0 0 0-1.2-1.6l-3.306 2.48-1.387-1.387a1 1 0 1 0-1.414 1.414l2 2a1 1 0 0 0 1.307.093l4-3Z"
             fill="currentColor"
            />
            <path
             fillRule="evenodd"
             clipRule="evenodd"
             d="M2.6 5A3.4 3.4 0 0 1 6 1.6h13A3.4 3.4 0 0 1 22.4 5v4.5a1.4 1.4 0 1 1-2.8 0V5a.6.6 0 0 0-.6-.6H6a.6.6 0 0 0-.6.6v18a.6.6 0 0 0 .6.6h6a1.4 1.4 0 1 1 0 2.8H6A3.4 3.4 0 0 1 2.6 23V5Z"
             fill="currentColor"
             fillOpacity="0.5"
            />
            <path
             fillRule="evenodd"
             clipRule="evenodd"
             d="M8 9a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1ZM8 14a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1ZM8 19a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Z"
             fill="currentColor"
             fillOpacity="0.5"
            />
           </svg>
          </div>
          <div className="hidden lg:block">
           <svg
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            color="#E7BB41"
           >
            <path
             fillRule="evenodd"
             clipRule="evenodd"
             d="M21 26a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm3.6-6.2a1 1 0 0 0-1.2-1.6l-3.306 2.48-1.387-1.387a1 1 0 1 0-1.414 1.414l2 2a1 1 0 0 0 1.307.093l4-3Z"
             fill="currentColor"
            />
            <path
             fillRule="evenodd"
             clipRule="evenodd"
             d="M2.6 5A3.4 3.4 0 0 1 6 1.6h13A3.4 3.4 0 0 1 22.4 5v4.5a1.4 1.4 0 1 1-2.8 0V5a.6.6 0 0 0-.6-.6H6a.6.6 0 0 0-.6.6v18a.6.6 0 0 0 .6.6h6a1.4 1.4 0 1 1 0 2.8H6A3.4 3.4 0 0 1 2.6 23V5Z"
             fill="currentColor"
             fillOpacity="0.5"
            />
            <path
             fillRule="evenodd"
             clipRule="evenodd"
             d="M8 9a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1ZM8 14a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1ZM8 19a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Z"
             fill="currentColor"
             fillOpacity="0.5"
            />
           </svg>
          </div>
          <div className="flex flex-col gap-[4px] lg:gap-[10px]">
           <div className="text-white text-left font-bold text-lg lg:text-2xl">
            Top-tier Security
           </div>
           <div className="text-quarternary2 lg:text-white text-left text-xs lg:text-base leading-4 lg:leading-6">
            Use advanced security mechanisms to ensure integrity
           </div>
          </div>
         </div>
         <div className="h-[164px] lg:h-[260px] flex flex-col items-start gap-[10px] lg:gap-5 bg-[#FFFFFF0D] border border-[#FFFFFF1A] rounded-[12px] lg:rounded-[18px] px-4 lg:px-7 3xl:px-10 pt-6 lg:pt-7 3xl:pt-10">
          <div className="block lg:hidden">
           <svg
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            color="#1EEFA4"
           >
            <path
             fillRule="evenodd"
             clipRule="evenodd"
             d="M25.388 4.93A1.496 1.496 0 0 0 24 4h-6a1.5 1.5 0 0 0 0 3h2.379L17 10.379l-3.44-3.44a1.5 1.5 0 0 0-2.15.031l-8.5 9a1.5 1.5 0 1 0 2.18 2.06l7.44-7.878 3.41 3.409a1.5 1.5 0 0 0 2.12 0l4.44-4.44V12a1.5 1.5 0 0 0 3 0V5.477a1.493 1.493 0 0 0-.112-.547Z"
             fill="currentColor"
            />
            <path
             fillRule="evenodd"
             clipRule="evenodd"
             d="M9 17.5a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1-3 0v-5A1.5 1.5 0 0 1 9 17.5ZM4 20.5A1.5 1.5 0 0 1 5.5 22v2a1.5 1.5 0 0 1-3 0v-2A1.5 1.5 0 0 1 4 20.5ZM14 14.5a1.5 1.5 0 0 1 1.5 1.5v8a1.5 1.5 0 0 1-3 0v-8a1.5 1.5 0 0 1 1.5-1.5ZM19 17.5a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1-3 0v-5a1.5 1.5 0 0 1 1.5-1.5ZM24 15.5a1.5 1.5 0 0 1 1.5 1.5v7a1.5 1.5 0 0 1-3 0v-7a1.5 1.5 0 0 1 1.5-1.5Z"
             fill="currentColor"
             fillOpacity="0.5"
            />
           </svg>
          </div>
          <div className="hidden lg:block">
           <svg
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            color="#1EEFA4"
           >
            <path
             fillRule="evenodd"
             clipRule="evenodd"
             d="M25.388 4.93A1.496 1.496 0 0 0 24 4h-6a1.5 1.5 0 0 0 0 3h2.379L17 10.379l-3.44-3.44a1.5 1.5 0 0 0-2.15.031l-8.5 9a1.5 1.5 0 1 0 2.18 2.06l7.44-7.878 3.41 3.409a1.5 1.5 0 0 0 2.12 0l4.44-4.44V12a1.5 1.5 0 0 0 3 0V5.477a1.493 1.493 0 0 0-.112-.547Z"
             fill="currentColor"
            />
            <path
             fillRule="evenodd"
             clipRule="evenodd"
             d="M9 17.5a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1-3 0v-5A1.5 1.5 0 0 1 9 17.5ZM4 20.5A1.5 1.5 0 0 1 5.5 22v2a1.5 1.5 0 0 1-3 0v-2A1.5 1.5 0 0 1 4 20.5ZM14 14.5a1.5 1.5 0 0 1 1.5 1.5v8a1.5 1.5 0 0 1-3 0v-8a1.5 1.5 0 0 1 1.5-1.5ZM19 17.5a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1-3 0v-5a1.5 1.5 0 0 1 1.5-1.5ZM24 15.5a1.5 1.5 0 0 1 1.5 1.5v7a1.5 1.5 0 0 1-3 0v-7a1.5 1.5 0 0 1 1.5-1.5Z"
             fill="currentColor"
             fillOpacity="0.5"
            />
           </svg>
          </div>
          <div className="flex flex-col gap-[4px] lg:gap-[10px]">
           <div className="text-white text-left font-bold text-lg lg:text-2xl">
            Instant Finality
           </div>
           <div className="text-quarternary2 lg:text-white text-left text-xs lg:text-base leading-4 lg:leading-6">
            Transactions are confirmed instantly, no waiting
           </div>
          </div>
         </div>
         <div className="h-[164px] lg:h-[260px] flex flex-col items-start gap-[10px] lg:gap-5 bg-[#FFFFFF0D] border border-[#FFFFFF1A] rounded-[12px] lg:rounded-[18px] px-4 lg:px-7 3xl:px-10 pt-6 lg:pt-7 3xl:pt-10">
          <div className="block lg:hidden">
           <svg
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            color="#ED7E17"
           >
            <g fill="currentColor">
             <path d="M43.242 25.584c-.376-.438-.583-1.001-.583-1.584 0-.583.207-1.146.583-1.584l2.996-3.455a2.449 2.449 0 0 0 .28-2.808L41.838 7.85a2.363 2.363 0 0 0-1.07-.992 2.289 2.289 0 0 0-1.435-.16l-4.401.912a2.292 2.292 0 0 1-1.64-.269 2.387 2.387 0 0 1-1.053-1.315L30.81 1.635a2.396 2.396 0 0 0-.855-1.183c-.4-.293-.878-.45-1.369-.449h-9.364a2.299 2.299 0 0 0-1.439.412c-.422.295-.74.723-.902 1.22L15.57 6.026a2.387 2.387 0 0 1-1.053 1.315 2.292 2.292 0 0 1-1.64.27L8.36 6.697a2.29 2.29 0 0 0-1.34.213 2.364 2.364 0 0 0-1.001.94l-4.682 8.302a2.448 2.448 0 0 0 .234 2.808l2.973 3.455c.375.438.582 1.001.582 1.584 0 .583-.207 1.146-.582 1.584L1.57 29.039a2.448 2.448 0 0 0-.234 2.808l4.682 8.303c.246.438.62.785 1.07.992.45.206.952.262 1.435.16l4.401-.912a2.292 2.292 0 0 1 1.64.268c.495.292.87.76 1.053 1.316l1.428 4.391c.162.497.48.925.902 1.22.423.294.928.44 1.439.412h9.364c.49.001.97-.156 1.369-.449.399-.292.698-.706.855-1.183l1.428-4.391a2.388 2.388 0 0 1 1.053-1.316 2.292 2.292 0 0 1 1.64-.268l4.4.912c.483.102.986.046 1.435-.16.45-.207.825-.554 1.07-.992l4.683-8.303a2.45 2.45 0 0 0-.28-2.808l-3.161-3.455Zm-3.489 3.215 1.873 2.16-2.996 5.327-2.763-.576a6.876 6.876 0 0 0-4.929.825 7.165 7.165 0 0 0-3.148 3.975l-.89 2.687h-5.993l-.842-2.735a7.165 7.165 0 0 0-3.148-3.975 6.876 6.876 0 0 0-4.93-.825l-2.762.576-3.043-5.303 1.873-2.16a7.302 7.302 0 0 0 1.788-4.799c0-1.77-.636-3.48-1.788-4.8l-1.873-2.16 2.997-5.278 2.762.576c1.686.353 3.44.06 4.929-.825a7.165 7.165 0 0 0 3.148-3.975l.89-2.711H26.9l.89 2.735a7.165 7.165 0 0 0 3.147 3.975 6.876 6.876 0 0 0 4.93.825l2.762-.576 2.996 5.327-1.873 2.16a7.304 7.304 0 0 0-1.767 4.775c0 1.76.629 3.459 1.767 4.775Z" />
             <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.904 14.401a9.203 9.203 0 0 0-5.203 1.618 9.552 9.552 0 0 0-3.449 4.308 9.822 9.822 0 0 0-.532 5.546 9.676 9.676 0 0 0 2.562 4.914 9.297 9.297 0 0 0 4.795 2.627c1.817.37 3.7.18 5.41-.546a9.419 9.419 0 0 0 4.203-3.535A9.77 9.77 0 0 0 33.27 24a9.722 9.722 0 0 0-2.743-6.787 9.25 9.25 0 0 0-6.622-2.812Zm0 14.398a4.601 4.601 0 0 1-2.601-.808 4.775 4.775 0 0 1-1.725-2.154 4.91 4.91 0 0 1-.266-2.773 4.838 4.838 0 0 1 1.281-2.458 4.648 4.648 0 0 1 2.398-1.313 4.577 4.577 0 0 1 2.705.273 4.71 4.71 0 0 1 2.101 1.768c.515.789.79 1.717.79 2.666a4.86 4.86 0 0 1-1.372 3.394 4.625 4.625 0 0 1-3.31 1.405Z"
              fillOpacity="0.5"
             />
            </g>
           </svg>
          </div>
          <div className="hidden lg:block">
           <svg
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            color="#ED7E17"
           >
            <g fill="currentColor">
             <path d="M43.242 25.584c-.376-.438-.583-1.001-.583-1.584 0-.583.207-1.146.583-1.584l2.996-3.455a2.449 2.449 0 0 0 .28-2.808L41.838 7.85a2.363 2.363 0 0 0-1.07-.992 2.289 2.289 0 0 0-1.435-.16l-4.401.912a2.292 2.292 0 0 1-1.64-.269 2.387 2.387 0 0 1-1.053-1.315L30.81 1.635a2.396 2.396 0 0 0-.855-1.183c-.4-.293-.878-.45-1.369-.449h-9.364a2.299 2.299 0 0 0-1.439.412c-.422.295-.74.723-.902 1.22L15.57 6.026a2.387 2.387 0 0 1-1.053 1.315 2.292 2.292 0 0 1-1.64.27L8.36 6.697a2.29 2.29 0 0 0-1.34.213 2.364 2.364 0 0 0-1.001.94l-4.682 8.302a2.448 2.448 0 0 0 .234 2.808l2.973 3.455c.375.438.582 1.001.582 1.584 0 .583-.207 1.146-.582 1.584L1.57 29.039a2.448 2.448 0 0 0-.234 2.808l4.682 8.303c.246.438.62.785 1.07.992.45.206.952.262 1.435.16l4.401-.912a2.292 2.292 0 0 1 1.64.268c.495.292.87.76 1.053 1.316l1.428 4.391c.162.497.48.925.902 1.22.423.294.928.44 1.439.412h9.364c.49.001.97-.156 1.369-.449.399-.292.698-.706.855-1.183l1.428-4.391a2.388 2.388 0 0 1 1.053-1.316 2.292 2.292 0 0 1 1.64-.268l4.4.912c.483.102.986.046 1.435-.16.45-.207.825-.554 1.07-.992l4.683-8.303a2.45 2.45 0 0 0-.28-2.808l-3.161-3.455Zm-3.489 3.215 1.873 2.16-2.996 5.327-2.763-.576a6.876 6.876 0 0 0-4.929.825 7.165 7.165 0 0 0-3.148 3.975l-.89 2.687h-5.993l-.842-2.735a7.165 7.165 0 0 0-3.148-3.975 6.876 6.876 0 0 0-4.93-.825l-2.762.576-3.043-5.303 1.873-2.16a7.302 7.302 0 0 0 1.788-4.799c0-1.77-.636-3.48-1.788-4.8l-1.873-2.16 2.997-5.278 2.762.576c1.686.353 3.44.06 4.929-.825a7.165 7.165 0 0 0 3.148-3.975l.89-2.711H26.9l.89 2.735a7.165 7.165 0 0 0 3.147 3.975 6.876 6.876 0 0 0 4.93.825l2.762-.576 2.996 5.327-1.873 2.16a7.304 7.304 0 0 0-1.767 4.775c0 1.76.629 3.459 1.767 4.775Z" />
             <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.904 14.401a9.203 9.203 0 0 0-5.203 1.618 9.552 9.552 0 0 0-3.449 4.308 9.822 9.822 0 0 0-.532 5.546 9.676 9.676 0 0 0 2.562 4.914 9.297 9.297 0 0 0 4.795 2.627c1.817.37 3.7.18 5.41-.546a9.419 9.419 0 0 0 4.203-3.535A9.77 9.77 0 0 0 33.27 24a9.722 9.722 0 0 0-2.743-6.787 9.25 9.25 0 0 0-6.622-2.812Zm0 14.398a4.601 4.601 0 0 1-2.601-.808 4.775 4.775 0 0 1-1.725-2.154 4.91 4.91 0 0 1-.266-2.773 4.838 4.838 0 0 1 1.281-2.458 4.648 4.648 0 0 1 2.398-1.313 4.577 4.577 0 0 1 2.705.273 4.71 4.71 0 0 1 2.101 1.768c.515.789.79 1.717.79 2.666a4.86 4.86 0 0 1-1.372 3.394 4.625 4.625 0 0 1-3.31 1.405Z"
              fillOpacity="0.5"
             />
            </g>
           </svg>
          </div>
          <div className="flex flex-col gap-[4px] lg:gap-[10px]">
           <div className="text-white text-left font-bold text-lg lg:text-2xl">
            Automatic Upgrades
           </div>
           <div className="text-quarternary2 lg:text-white text-left text-xs lg:text-base leading-4 lg:leading-6">
            Automatic system updates to improve features and security
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div className="w-full flex items-center justify-center multichain-bg px-[18px] lg:px-16 pt-20 lg:pt-[130px] pb-20 lg:pb-[130px]">
       <div className="max-w-[1792px] w-full h-full flex flex-row items-center justify-between">
        <div className="flex flex-col items-center min-w-0">
         <div
          data-aos="fade-up"
          className="w-full flex flex-col items-start gap-3"
         >
          <div className="font-bold text-lg lg:text-4xl 3xl:text-6xl text-center lg:text-left section-title-bg">
           Permissionless Security Support Protocol for All Web3 Communities
          </div>
          <div className="text-accent-content text-xs lg:text-lg 3xl:text-2xl text-center lg:text-left">
           The Zalva platform will equip the community with powerful tools to
           launch the desired service without any difficulties in the
           development process.
          </div>
         </div>
         <div
          data-aos="fade-up"
          className="h-[207px] flex lg:hidden items-center justify-center mt-3"
         >
          <img
           alt="toolkit image"
           loading="lazy"
           width={360}
           height={207}
           decoding="async"
           data-nimg={1}
           style={{ color: "transparent" }}
           src="/spaceid/landing-toolkit.webp"
          />
         </div>
         <div
          data-aos="fade-up"
          className="w-full flex flex-col items-center lg:items-start justify-center"
         >
          <div className="w-full lg:min-w-[637px] lg:w-auto flex flex-col lg:flex-row items-center justify-center gap-4 mt-[24px] lg:mt-[54px] rounded-xl bg-button01/60 px-[16px] lg:px-6 py-3">
           <div className="w-full flex items-center justify-start gap-3 text-[#1fefa3]">
            <svg
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             width={20}
             height={20}
            >
             <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 3a1 1 0 0 0-2 0v18a1 1 0 0 0 1 1h18a1 1 0 1 0 0-2H4V3Zm17.825 3.067a1 1 0 1 0-1.649-1.134l-4.663 6.783-2.18-3.27a1 1 0 0 0-1.657-.013l-5.5 8a1 1 0 1 0 1.648 1.134l4.664-6.783 2.18 3.27a1 1 0 0 0 1.657.012l5.5-8Z"
              fill="currentColor"
             />
            </svg>
            <div className="lg:w-[120px] text-white font-bold text-xs lg:text-sm">
             Developer Tooling
            </div>
           </div>
           <div className="w-full lg:w-[2px] h-px lg:h-8 bg-[#344B57A6]" />
           <div className="w-full flex items-center justify-start gap-3 text-[#1fefa3]">
            <svg
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             width={20}
             height={20}
            >
             <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 8a2 2 0 1 0 4 0 2 2 0 0 0-4 0Zm2-4a4.002 4.002 0 0 1 3.874 3H22a1 1 0 1 1 0 2h-2.126a4.002 4.002 0 0 1-7.748 0H2a1 1 0 0 1 0-2h10.126c.444-1.725 2.01-3 3.874-3Zm6 13a1 1 0 1 0 0-2H11.874a4.002 4.002 0 0 0-7.748 0H2a1 1 0 1 0 0 2h2.126a4.002 4.002 0 0 0 7.748 0H22ZM8 18a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
              fill="currentColor"
             />
            </svg>
            <div className="lg:w-[120px] text-white font-bold text-xs lg:text-sm">
             User-Friendly Environment
            </div>
           </div>
           <div className="w-full lg:w-[2px] h-px lg:h-8 bg-[#344B57A6]" />
           <div className="w-full flex items-center justify-start gap-3 text-[#1fefa3]">
            <svg
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             width={20}
             height={20}
            >
             <g fill="currentColor">
              <path d="M43.242 25.584c-.376-.438-.583-1.001-.583-1.584 0-.583.207-1.146.583-1.584l2.996-3.455a2.449 2.449 0 0 0 .28-2.808L41.838 7.85a2.363 2.363 0 0 0-1.07-.992 2.289 2.289 0 0 0-1.435-.16l-4.401.912a2.292 2.292 0 0 1-1.64-.269 2.387 2.387 0 0 1-1.053-1.315L30.81 1.635a2.396 2.396 0 0 0-.855-1.183c-.4-.293-.878-.45-1.369-.449h-9.364a2.299 2.299 0 0 0-1.439.412c-.422.295-.74.723-.902 1.22L15.57 6.026a2.387 2.387 0 0 1-1.053 1.315 2.292 2.292 0 0 1-1.64.27L8.36 6.697a2.29 2.29 0 0 0-1.34.213 2.364 2.364 0 0 0-1.001.94l-4.682 8.302a2.448 2.448 0 0 0 .234 2.808l2.973 3.455c.375.438.582 1.001.582 1.584 0 .583-.207 1.146-.582 1.584L1.57 29.039a2.448 2.448 0 0 0-.234 2.808l4.682 8.303c.246.438.62.785 1.07.992.45.206.952.262 1.435.16l4.401-.912a2.292 2.292 0 0 1 1.64.268c.495.292.87.76 1.053 1.316l1.428 4.391c.162.497.48.925.902 1.22.423.294.928.44 1.439.412h9.364c.49.001.97-.156 1.369-.449.399-.292.698-.706.855-1.183l1.428-4.391a2.388 2.388 0 0 1 1.053-1.316 2.292 2.292 0 0 1 1.64-.268l4.4.912c.483.102.986.046 1.435-.16.45-.207.825-.554 1.07-.992l4.683-8.303a2.45 2.45 0 0 0-.28-2.808l-3.161-3.455Zm-3.489 3.215 1.873 2.16-2.996 5.327-2.763-.576a6.876 6.876 0 0 0-4.929.825 7.165 7.165 0 0 0-3.148 3.975l-.89 2.687h-5.993l-.842-2.735a7.165 7.165 0 0 0-3.148-3.975 6.876 6.876 0 0 0-4.93-.825l-2.762.576-3.043-5.303 1.873-2.16a7.302 7.302 0 0 0 1.788-4.799c0-1.77-.636-3.48-1.788-4.8l-1.873-2.16 2.997-5.278 2.762.576c1.686.353 3.44.06 4.929-.825a7.165 7.165 0 0 0 3.148-3.975l.89-2.711H26.9l.89 2.735a7.165 7.165 0 0 0 3.147 3.975 6.876 6.876 0 0 0 4.93.825l2.762-.576 2.996 5.327-1.873 2.16a7.304 7.304 0 0 0-1.767 4.775c0 1.76.629 3.459 1.767 4.775Z" />
              <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M23.904 14.401a9.203 9.203 0 0 0-5.203 1.618 9.552 9.552 0 0 0-3.449 4.308 9.822 9.822 0 0 0-.532 5.546 9.676 9.676 0 0 0 2.562 4.914 9.297 9.297 0 0 0 4.795 2.627c1.817.37 3.7.18 5.41-.546a9.419 9.419 0 0 0 4.203-3.535A9.77 9.77 0 0 0 33.27 24a9.722 9.722 0 0 0-2.743-6.787 9.25 9.25 0 0 0-6.622-2.812Zm0 14.398a4.601 4.601 0 0 1-2.601-.808 4.775 4.775 0 0 1-1.725-2.154 4.91 4.91 0 0 1-.266-2.773 4.838 4.838 0 0 1 1.281-2.458 4.648 4.648 0 0 1 2.398-1.313 4.577 4.577 0 0 1 2.705.273 4.71 4.71 0 0 1 2.101 1.768c.515.789.79 1.717.79 2.666a4.86 4.86 0 0 1-1.372 3.394 4.625 4.625 0 0 1-3.31 1.405Z"
               fillOpacity="0.5"
              />
             </g>
            </svg>
            <div className="lg:w-40 text-white font-bold text-xs lg:text-sm">
             Peer-to-Peer Scalability
            </div>
           </div>
          </div>
          <LinkWhitepaper className="w-full lg:w-auto mt-3 lg:mt-[44px]">
           <div className="w-full lg:w-auto h-8 lg:h-12 flex items-center justify-center gap-2 rounded-[8px] border border-[#1fefa3] lg:border-transparent lg:bg-[#1fefa3] text-[#1fefa3] lg:text-[#072331] text-xs lg:text-base font-bold px-0 lg:px-8">
            Read Whitepaper
            <div className="hidden lg:block">
             <svg
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
             >
              <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M14.94 4.94a1.5 1.5 0 0 1 2.12 0l8 8a1.497 1.497 0 0 1-.005 2.127L17.06 23.06a1.5 1.5 0 0 1-2.122-2.122l5.44-5.439H4a1.5 1.5 0 0 1 0-3h16.379l-5.44-5.44a1.5 1.5 0 0 1 0-2.12Z"
               fill="currentColor"
              />
             </svg>
            </div>
            <div className="block lg:hidden">
             <svg
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={14}
             >
              <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M14.94 4.94a1.5 1.5 0 0 1 2.12 0l8 8a1.497 1.497 0 0 1-.005 2.127L17.06 23.06a1.5 1.5 0 0 1-2.122-2.122l5.44-5.439H4a1.5 1.5 0 0 1 0-3h16.379l-5.44-5.44a1.5 1.5 0 0 1 0-2.12Z"
               fill="currentColor"
              />
             </svg>
            </div>
           </div>
          </LinkWhitepaper>
         </div>
        </div>
        <div
         data-aos="fade-up"
         className="hidden relative min-w-[50%] w-[50%] lg:flex items-center justify-center"
         style={{ height: "404.598px" }}
        >
         <img
          alt="toolkit image"
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          style={{
           position: "absolute",
           height: "100%",
           width: "100%",
           left: 0,
           top: 0,
           right: 0,
           bottom: 0,
           objectFit: "contain",
           color: "transparent",
          }}
          sizes="100vw"
          src="/spaceid/landing-toolkit.webp"
         />
        </div>
       </div>
      </div>
      <div className="w-full flex justify-center sdk-bg px-[18px] lg:px-16 pt-20 lg:pt-40 pb-20 lg:pb-40">
       <div className="max-w-[1792px] flex flex-col items-center">
        <div
         data-aos="fade-up"
         className="w-full flex flex-col items-center gap-[6px] lg:gap-3"
        >
         <div className="font-bold text-lg lg:text-4xl 3xl:text-6xl section-title-bg">
          Zalva Solution at Web3
         </div>
         <div className="lg:max-w-[80%] text-accent-content text-xs lg:text-lg 3xl:text-2xl text-center">
          The only service you need to simplify the integration of verified Web3
          solutions with a comprehensive approach, simplifying the process for
          the community once and for all.
         </div>
        </div>
        <div
         data-aos="fade-up"
         className="flex flex-col lg:flex-row items-center 2xl:items-baseline gap-2 lg:gap-8 mt-[14px] lg:mt-6"
        >
         <div className="text-[#0DC28D] font-bold text-xs lg:text-2xl text-center">
          Increase transaction speed
         </div>
         <div className="w-full lg:w-px h-px lg:h-4 bg-[#344B57A6]" />
         <div className="text-[#0DC28D] font-bold text-xs lg:text-2xl text-center">
          Reduced transaction fees
         </div>
         <div className="w-full lg:w-px h-px lg:h-4 bg-[#344B57A6]" />
         <div className="text-[#0DC28D] font-bold text-xs lg:text-2xl text-center">
          Maintain confidentiality
         </div>
        </div>
        <div
         data-aos="fade-up"
         className="relative w-full h-[190px] lg:h-[440px] lg:mt-6 flex items-center justify-center"
        >
         <div className="absolute lg:relative w-[120vw] lg:w-full h-full -left-10 -right-10 lg:left-0 lg:right-0 top-2 lg:flex items-center justify-center">
          <img
           alt="sdk image"
           loading="lazy"
           decoding="async"
           data-nimg="fill"
           style={{
            color: "transparent",
            objectFit: "contain",
            position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
           }}
           src="/spaceid/landing-sdk.webp"
           sizes="100vw"
          />
         </div>
        </div>
        <LinkWhitepaper data-aos="fade-up" className="w-full lg:w-auto mt-6">
         <div className="w-full lg:w-auto h-8 lg:h-12 flex items-center justify-center text-[#1fefa3] lg:text-[#07222f] gap-1 lg:gap-2 font-bold text-xs lg:text-base px-8 rounded-[8px] border border-[#1fefa3] bg-[#1F2A2E99] lg:bg-[#1fefa3]">
          Build with us
          <div className="hidden lg:block">
           <svg
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
           >
            <path
             fillRule="evenodd"
             clipRule="evenodd"
             d="M14.94 4.94a1.5 1.5 0 0 1 2.12 0l8 8a1.497 1.497 0 0 1-.005 2.127L17.06 23.06a1.5 1.5 0 0 1-2.122-2.122l5.44-5.439H4a1.5 1.5 0 0 1 0-3h16.379l-5.44-5.44a1.5 1.5 0 0 1 0-2.12Z"
             fill="currentColor"
            />
           </svg>
          </div>
          <div className="block lg:hidden">
           <svg
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={14}
            height={14}
           >
            <path
             fillRule="evenodd"
             clipRule="evenodd"
             d="M14.94 4.94a1.5 1.5 0 0 1 2.12 0l8 8a1.497 1.497 0 0 1-.005 2.127L17.06 23.06a1.5 1.5 0 0 1-2.122-2.122l5.44-5.439H4a1.5 1.5 0 0 1 0-3h16.379l-5.44-5.44a1.5 1.5 0 0 1 0-2.12Z"
             fill="currentColor"
            />
           </svg>
          </div>
         </div>
        </LinkWhitepaper>
       </div>
      </div>
      <div className="max-w-[1792px] w-[calc(100%-36px)] lg:w-[calc(100%-128px)] flex flex-col items-center lg:mx-16 pt-20 lg:pt-40 pb-20 lg:pb-40">
       <div className="w-full flex flex-col items-center">
        <div className="flex flex-col-reverse lg:flex-row items-center gap-2 font-bold text-lg lg:text-4xl 3xl:text-6xl">
         <div data-aos="fade-up" className="section-title-bg">
          Trusted by Leading Experts
         </div>
        </div>
        <Marquee className="mt-6 lg:mt-[44px] w-[97vw]" loop={0} speed={40}>
         {partners.map((partner, index) => (
          <div
           key={index}
           className="w-12 mr-6 h-12 lg:w-20 lg:h-20 flex items-center justify-center rounded-[50%] border border-[#FFFFFF1A]"
          >
           <img
            alt=""
            loading="lazy"
            width={32}
            height={32}
            decoding="async"
            data-nimg={1}
            src={partner}
            style={{
             color: "transparent",
             borderRadius: "50%",
            }}
           />
          </div>
         ))}
        </Marquee>
        <div
         data-aos="fade-up"
         className="w-full grid grid-cols-1 lg:grid-cols-2 gap-[10px] lg:gap-8 mt-[52px] lg:mt-[68px]"
        >
         <div className="flex flex-col items-start gap-3 lg:gap-8 rounded-3xl border border-[#344B57A6] p-6 lg:p-8">
          <img
           alt="agent icon"
           loading="lazy"
           width={40}
           height={40}
           decoding="async"
           data-nimg={1}
           style={{ color: "transparent" }}
           src="/spaceid/ic-trustwallet.svg"
          />
          <div className="text-white font-bold text-sm lg:text-lg">
           Zalva Network is bringing a real breakthrough to the BNB Chain
           ecosystem. With its excellent scalability and top-notch security, it
           provides a powerful platform for dApp development. We are impressed
           with the transaction processing speed and compatibility with existing
           Layer 1 solutions.
          </div>
          <div className="w-full flex justify-start items-center gap-3">
           <img
            alt="avatar"
            loading="lazy"
            width={24}
            height={24}
            decoding="async"
            data-nimg={1}
            className="rounded-[50%]"
            style={{ color: "transparent" }}
            src="/spaceid/landing-comment-kelly-guo.png"
           />
           <div className="flex flex-col items-start">
            <div className="text-white text-xs lg:text-sm font-semibold">
             BSCScan
            </div>
            <div className="text-accent-content text-xs lg:text-sm -mt-1 lg:mt-0">
             Business Development
            </div>
           </div>
          </div>
         </div>
         <div className="flex flex-col items-start gap-3 lg:gap-8 rounded-3xl border border-[#344B57A6] p-6 lg:p-8">
          <img
           alt="agent icon"
           loading="lazy"
           width={40}
           height={40}
           decoding="async"
           data-nimg={1}
           style={{ color: "transparent" }}
           src="/spaceid/ic-bscscan.svg"
          />
          <div className="text-white font-bold text-sm lg:text-lg">
           Zalva Network is a promising Layer 2 solution that offers a smooth
           and secure user experience. Low transaction fees and multi-chain
           interoperability are key factors that make Zalva a top choice for
           those looking to optimize their wallet and trading experience.
          </div>
          <div className="w-full flex justify-start items-center gap-3">
           <img
            alt="avatar"
            loading="lazy"
            width={24}
            height={24}
            decoding="async"
            data-nimg={1}
            className="rounded-[50%]"
            style={{ color: "transparent" }}
            src="/spaceid/landing-comment-wee.png"
           />
           <div className="flex flex-col items-start">
            <div className="text-white text-xs lg:text-sm font-semibold">
             MetaMask
            </div>
            <div className="text-accent-content text-xs lg:text-sm -mt-1 lg:mt-0">
             Business Development
            </div>
           </div>
          </div>
         </div>
        </div>
        <a
         target="_blank"
         data-aos="fade-up"
         className="w-full lg:w-auto mt-9"
         href="https://docs.space.id/overview/integration-partners"
        >
         {/* <div className="w-full lg:w-auto h-8 lg:h-12 flex items-center justify-center text-[#1fefa3] gap-1 lg:gap-2 font-bold text-xs lg:text-base px-8 rounded-[8px] border border-white/5 bg-[#1F2A2E99]">
                    View all partners
                    <div>
                      <svg
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.94 4.94a1.5 1.5 0 0 1 2.12 0l8 8a1.497 1.497 0 0 1-.005 2.127L17.06 23.06a1.5 1.5 0 0 1-2.122-2.122l5.44-5.439H4a1.5 1.5 0 0 1 0-3h16.379l-5.44-5.44a1.5 1.5 0 0 1 0-2.12Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div> */}
        </a>
       </div>
      </div>
      <News />
      <div className="max-w-[1792px] w-[calc(100%-36px)] lg:w-[calc(100%-128px)] flex flex-col items-center lg:mx-16 pt-10 lg:pt-20 pb-10 lg:pb-40">
       <div
        data-aos="fade-up"
        className="font-bold text-lg lg:text-6xl section-title-bg"
       >
        Backed by the Visionaries
       </div>
       <div
        data-aos="fade-up"
        className="w-full grid grid-cols-2 lg:grid-cols-4 gap-4 mt-4 lg:mt-9"
       >
        <a target="_blank" className="w-full" href="https://labs.binance.com/">
         <div className="w-full h-20 lg:h-[120px] flex justify-center items-center rounded-[12px] lg:rounded-3xl bg-[#FFFFFF0D]">
          <div className="h-[27px] lg:h-[42px] 3xl:h-[52px] w-[104px] lg:w-[162px] 3xl:w-[201px] relative">
           <img
            alt="binance labs"
            loading="lazy"
            decoding="async"
            data-nimg="fill"
            style={{
             position: "absolute",
             height: "100%",
             width: "100%",
             left: 0,
             top: 0,
             right: 0,
             bottom: 0,
             color: "transparent",
            }}
            sizes="100vw"
            src="/spaceid/ic-binance-labs.svg"
           />
          </div>
         </div>
        </a>
        <a target="_blank" className="w-full" href="https://polychain.capital/">
         <div className="w-full h-20 lg:h-[120px] flex justify-center items-center rounded-[12px] lg:rounded-3xl bg-[#FFFFFF0D]">
          <div className="h-[30px] lg:h-[40px] 3xl:h-[50px] w-[94px] lg:w-[126px] 3xl:w-[158px] relative">
           <img
            alt="polychain capital"
            loading="lazy"
            decoding="async"
            data-nimg="fill"
            style={{
             position: "absolute",
             height: "100%",
             width: "100%",
             left: 0,
             top: 0,
             right: 0,
             bottom: 0,
             color: "transparent",
            }}
            sizes="100vw"
            src="/spaceid/ic-polychain-capital.svg"
           />
          </div>
         </div>
        </a>
        <a target="_blank" className="w-full" href="https://bulletlabs.com/">
         <div className="w-full h-20 lg:h-[120px] flex justify-center items-center rounded-[12px] lg:rounded-3xl bg-[#FFFFFF0D]">
          <div className="h-[12px] lg:h-[20px] 3xl:h-[28px] w-[95px] lg:w-[158px] 3xl:w-[222px] relative">
           <img
            alt="bullet labs"
            loading="lazy"
            decoding="async"
            data-nimg="fill"
            style={{
             position: "absolute",
             height: "100%",
             width: "100%",
             left: 0,
             top: 0,
             right: 0,
             bottom: 0,
             color: "transparent",
            }}
            src="/spaceid/ic-bullet-labs.svg"
           />
          </div>
         </div>
        </a>
        <a target="_blank" className="w-full" href="https://dao5.com/">
         <div className="w-full h-20 lg:h-[120px] flex justify-center items-center rounded-[12px] lg:rounded-3xl bg-[#FFFFFF0D]">
          <div className="h-[35px] lg:h-[50px] 3xl:h-[60px] w-[98px] lg:w-[140px] 3xl:w-[168px] relative">
           <img
            alt="dao5"
            loading="lazy"
            decoding="async"
            data-nimg="fill"
            style={{
             position: "absolute",
             height: "100%",
             width: "100%",
             left: 0,
             top: 0,
             right: 0,
             bottom: 0,
             color: "transparent",
            }}
            sizes="100vw"
            src="/spaceid/ic-dao5.png"
           />
          </div>
         </div>
        </a>
       </div>
      </div>
     </div>
    </main>
   </main>
  </section>
 );
}

function FeaturedSecureService(props) {
 return (
  <div
   data-aos="fade-up"
   className="flex flex-col items-start justify-between bg-[#1F2A2E99] rounded-xl border border-[#FFFFFF0D] px-3 pt-3 pb-[6px] lg:pb-3 shadow-menu cursor-pointer"
  >
   <div className="max-w-full flex flex-row items-center justify-center gap-3 h-10">
    <img
     alt=""
     loading="lazy"
     width={40}
     height={40}
     decoding="async"
     data-nimg={1}
     className="rounded flex-shrink-0"
     src={props.logo}
     style={{ color: "transparent" }}
    />
    <div className="overflow-hidden flex flex-col items-start">
     <div className="max-w-full flex flex-row items-center justify-center gap-1 text-white text-lg lg:text-xl font-bold">
      <div className="overflow-hidden truncate whitespace-nowrap overflow-ellipsis">
       {props.title}
      </div>
      <div className="flex-shrink-0 flex items-center justify-center">
       (
       <div
        className="flex flex-row text-base lg:text-xl font-bold"
        style={{ maxWidth: 180 }}
       >
        <div className="truncate shrink whitespace-nowrap text-ellipsis" />
        <span style={{ color: props.color }}>{props.token}</span>
        <div className="self-center justify-self-center" />
       </div>
       )
      </div>
      <div className="flex-shrink-0 ml-[2px] mt-1">
       <svg
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        heigh={14}
       >
        <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M11.598.866a.89.89 0 0 0-1.196 0L8.347 2.73a.89.89 0 0 1-.632.23l-2.773-.107a.89.89 0 0 0-.916.769l-.376 2.75a.89.89 0 0 1-.337.582l-2.193 1.7a.89.89 0 0 0-.208 1.178l1.48 2.348a.89.89 0 0 1 .117.663l-.587 2.712a.89.89 0 0 0 .598 1.036l2.642.847a.89.89 0 0 1 .516.433l1.293 2.455a.89.89 0 0 0 1.124.409l2.569-1.05a.89.89 0 0 1 .673 0l2.569 1.05a.89.89 0 0 0 1.124-.41l1.293-2.454a.89.89 0 0 1 .516-.433l2.642-.847a.89.89 0 0 0 .598-1.036l-.587-2.712a.89.89 0 0 1 .117-.663l1.48-2.348a.89.89 0 0 0-.208-1.178l-2.194-1.7a.89.89 0 0 1-.336-.583l-.376-2.75a.89.89 0 0 0-.916-.768l-2.773.107a.89.89 0 0 1-.632-.23L11.598.866Zm4.273 7.917a.917.917 0 1 0-1.492-1.066l-4 5.602L7.93 11.22a.917.917 0 0 0-1.193 1.392l3.209 2.75a.916.916 0 0 0 1.342-.164l4.583-6.416Z"
         fill="#1EEFA4"
        />
       </svg>
      </div>
     </div>
     <div className="flex flex-row items-center justify-start gap-1 text-xs text-quarternary">
      <span>By</span>
      <div
       className="flex flex-row text-xs font-semibold"
       style={{ maxWidth: 180 }}
      >
       <div className="truncate shrink whitespace-nowrap text-ellipsis">
        {props.by}
       </div>
       <span style={{ color: props.color }}>{props.token}</span>
       <div className="self-center justify-self-center" />
      </div>
     </div>
    </div>
   </div>
   <div className="w-full h-px bg-[#293E4873] mt-[10px]" />
   <div className="w-full flex flex-row items-center justify-between gap-3 lg:gap-6">
    <div className="flex-1 h-full flex flex-col items-start justify-end">
     <div className="text-quarternary2 text-xs leading-4">24h Registered</div>
     <div className="text-white font-semibold text-lg">{props.registered}</div>
    </div>
    <div className="flex-1 h-full flex flex-col items-start justify-end">
     <div className="text-quarternary2 text-xs leading-4">Volume</div>
     <div className="text-white font-semibold text-lg">
      <div className="flex items-center font-semibold w-fit text-base">
       <div className="mr-[2px]">
        <svg
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         width="0.8em"
         height="0.8em"
        >
         <g clipPath="url(#ic-eth_svg__a)">
          <path
           fillRule="evenodd"
           clipRule="evenodd"
           d="M4.286 12.224 11.786 0l7.499 12.224-7.499 4.365-7.5-4.365Zm15 1.394L11.786 24l-7.5-10.382 7.5 4.353 7.502-4.353Z"
           fill="currentColor"
          />
         </g>
         <defs>
          <clipPath id="ic-eth_svg__a">
           <path fill="currentColor" d="M0 0h24v24H0z" />
          </clipPath>
         </defs>
        </svg>
       </div>
       <div>{props.volume}</div>
      </div>
     </div>
    </div>
    <div className="flex-1 h-full flex flex-col items-start justify-end">
     <div className="text-quarternary2 text-xs leading-4">Total Registered</div>
     <div className="text-white font-semibold text-lg">
      {props.totalRegistered}
     </div>
    </div>
    <div className="flex-1 h-full flex flex-col items-start justify-end">
     <div className="text-quarternary2 text-xs leading-4">Owners</div>
     <div className="text-white font-semibold text-lg">{props.owners}</div>
    </div>
   </div>
  </div>
 );
}
