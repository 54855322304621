import React from "react";

export default function Secured() {
 return (
  <section className="section pt-s100">
   <div className="container">
    <div className="flex flex-col justify-center text-center items-center">
     <div className="max-w-[100ch]">
      <h2 className="text-[3rem] font-medium lg:text-[5.25rem]">
       Secured on the Zalva Network
      </h2>
     </div>
     <div className="max-w-[68ch] mt-s16">
      <div className="text-p_21 text-c-neutral-500 leading-normal">
       In exchange for securing the Zalva blockchain, transaction fees and
       staking rewards will be distributed to ZAL validators and delegators.
      </div>
     </div>
    </div>
    <img
     src="/cdn/65b62e4f16e06158e2561f8c_secured-x-2.webp"
     alt="Metalic X with text: Accelerate, build, grow and contribute"
     className="img_secured"
    />
   </div>
  </section>
 );
}
