import React from "react";
import { cn } from "../../utils/helpers";

export default function HamburgerButton({ className, onClick, toggle }) {
 return (
  <button
   className={cn("w-s32 h-s32 opacity-50 invert-[97%]", className)}
   onClick={onClick}
  >
   <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 850 850"
    width={850}
    height={850}
    preserveAspectRatio="xMidYMid meet"
    style={{
     width: "100%",
     height: "100%",
     transform: "translate3d(0px, 0px, 0px)",
     contentVisibility: "visible",
    }}
   >
    <defs>
     <clipPath id="__lottie_element_2">
      <rect width={850} height={850} x={0} y={0} />
     </clipPath>
    </defs>
    <g clipPath="url(#__lottie_element_2)">
     <g
      transform={
       toggle ? "matrix(0,1,-1,0,425,425)" : "matrix(1,0,0,1,425,425)"
      }
      opacity={1}
      className="transition-all duration-500"
      style={{ display: "block" }}
     >
      <g opacity={1} transform="matrix(1,0,0,1,0,0)">
       <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fillOpacity={0}
        stroke="rgb(0,0,0)"
        strokeOpacity={1}
        strokeWidth={60}
        d={
         toggle
          ? " M230,-230 C230,-230 0,0 0,0 C0,0 -228,-228 -228,-228"
          : " M324,-250 C324,-250 3,-250 3,-250 C3,-250 -324,-250 -324,-250"
        }
       />
      </g>
     </g>
     <g
      transform={
       toggle ? "matrix(0,1,-1,0,425,425)" : "matrix(1,0,0,1,425,425)"
      }
      opacity={1}
      className="transition-all duration-500"
      style={{ display: "block" }}
     >
      <g opacity={1} transform="matrix(1,0,0,1,0,0)">
       <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fillOpacity={0}
        stroke="rgb(0,0,0)"
        strokeOpacity={1}
        strokeWidth={60}
        d={
         toggle
          ? " M-230,230 C-230,230 -1,1 -1,1 C-1,1 230,230 230,230"
          : " M-324,250 C-324,250 3,250 3,250 C3,250 324,250 324,250"
        }
       />
      </g>
     </g>
     <g
      transform={
       toggle ? "matrix(0,1,-1,0,425,425)" : "matrix(1,0,0,1,425,425)"
      }
      opacity={1}
      className="transition-all duration-500"
      style={{ display: "block" }}
     >
      <g opacity={1} transform="matrix(1,0,0,1,0,0)">
       <path
        strokeLinecap="round"
        strokeLinejoin="miter"
        fillOpacity={0}
        strokeMiterlimit={10}
        stroke="rgb(0,0,0)"
        strokeOpacity={1}
        strokeWidth={60}
        d={toggle ? "M0 0" : " M-324,0 C-324,0 324,0 324,0"}
       />
      </g>
     </g>
    </g>
   </svg>
  </button>
 );
}
