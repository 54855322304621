import React from "react";

export default function EarnReward() {
 return (
  <section className="section">
   <div className="container relative pt-s100">
    <div className="">
     <div className="max-w-[50ch]">
      <h2 className="text-[3rem] font-medium lg:text-[5.25rem] leading-[1]">
       Benefits of Staking
      </h2>
     </div>
     <div className="max-w-[50ch] mt-s16">
      <p className="text-p_21 text-c-neutral-500 leading-normal">
       Staking helps secure the Zalva Network by providing a layer of protection
       against attacks and fraudulent activities.{" "}
      </p>
     </div>
    </div>
    <div className="absolute inset-0 w-full h-full flex justify-center pointer-events-none">
     <img
      src="/cdn/65b12a02913368162d17128e_earn-visual.webp"
      loading="lazy"
      alt=""
      className="img_earn"
     />
    </div>
   </div>
   <div className="container mt-s100">
    <div className="grid lg:grid-cols-3 gap-x-s16 gap-y-s56">
     <div>
      <div className="bento_item w-full">
       <div className="bento-inner_vertical-24">
        <h2 className="text-c-neutral-500 font-medium">Flexible</h2>
        <div className="ml-auto _100">
         <div className="text-[2.5rem] lg:text-[3.5rem] flex items-center">
          <span>Rewards</span>
         </div>
        </div>
       </div>
      </div>
      <div className="px-s24 pt-s16">
       <div className="text-p_21 text-c-neutral-500 leading-normal">
        This reward not only helps to encourage users to participate but also
        provides a source of passive income.
       </div>
      </div>
     </div>
     <div>
      <div className="bento_item w-full">
       <div className="bento-inner_vertical-24">
        <h2 className="text-c-neutral-500 font-medium">Average APR</h2>
        <div className="ml-auto _100">
         <div className="text-[2.5rem] lg:text-[3.5rem] text-right">
          <span>~128%</span>
         </div>
        </div>
       </div>
      </div>
      <div className="px-s24 pt-s16">
       <div className="text-p_21 text-c-neutral-500 leading-normal">
        Zalva Network often allows users the flexibility to withdraw or add
        tokens to the staking pool.
       </div>
      </div>
     </div>
     <div>
      <div className="bento_item w-full">
       <div className="bento-inner_vertical-24">
        <h2 className="text-c-neutral-500 font-medium">Unstake Period</h2>
        <div className="ml-auto _100">
         <div className="text-[2.5rem] lg:text-[3.5rem] text-right">
          <span>30 days</span>
         </div>
        </div>
       </div>
      </div>
      <div className="px-s24 pt-s16">
       <div className="text-p_21 text-c-neutral-500 leading-normal">
        After the staking period, users can withdraw tokens along with rewards.
        This process is usually simple and can be easily done through the
        platform interface.
       </div>
      </div>
     </div>
    </div>
   </div>
  </section>
 );
}
