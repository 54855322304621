import React from "react";
import { cn } from "../../utils/helpers";
import Button from "../ui/Button";

export default function GetStartedCard({ item, className, index }) {
 return (
  <article className={cn("get-started mt-[-4.69rem]", className)}>
   <div
    className={cn(
     "container relative !px-0 rounded-[2.5rem]",
     index % 2 === 0 ? "bg-c-black-1000" : "bg-c-neutral-900"
    )}
   >
    <div className="gap-s8 grid lg:grid-cols-7 items-center max-sm:py-s150 py-s100">
     <div className="step_number">{index + 1}</div>
     <div className="lg:col-span-4 px-s100">
      <h3 className="text-[2rem]">{item.title}</h3>
      <div className="text-p_21 text-c-neutral-500 leading-normal mt-s8 mb-s60">
       {item.description}
      </div>
      <Button url={item.link} color="neutral" className="w-fit">
       <div className="p_16">{item.linkText}</div>
       <div className="w-s24 h-s24 flex justify-center items-center">
        <svg
         width="100%"
         height="100%"
         viewBox="0 0 25 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
        >
         <path
          d="M23.7656 8.5625L17.0156 15.3125C16.7344 15.5938 16.2188 15.5938 15.9375 15.3125C15.6562 15.0312 15.6562 14.5156 15.9375 14.2344L21.4219 8.75H0.75C0.328125 8.75 0 8.42188 0 8C0 7.625 0.328125 7.25 0.75 7.25H21.4219L15.9375 1.8125C15.6562 1.53125 15.6562 1.01562 15.9375 0.734375C16.2188 0.453125 16.7344 0.453125 17.0156 0.734375L23.7656 7.48438C24.0469 7.76562 24.0469 8.28125 23.7656 8.5625Z"
          fill="currentColor"
         />
        </svg>
       </div>
      </Button>
     </div>
     <div className="lg:col-span-3">
      <img src={item.img} alt="" className="w-[70%] aspect-square" />
     </div>
    </div>
   </div>
  </article>
 );
}
