import { useData } from "fuhi-web3ts";
import React from "react";
import Marquee from "react-fast-marquee";
import Partner from "../../components/cards/Partner";
import { LinkWhitepaper } from "../../components/LinkCollection";
import Button from "../../components/ui/Button";

export default function MarqueePartner() {
 const { data } = useData({ path: "/data/partners.json", default: [] });
 return (
  <section className="section py-s100">
   <div className="container">
    <div className="flex max-md:flex-col gap-y-s24 gap-x-s64">
     <div className="max-w-[20rem] space-y-s32 max-md:order-2">
      <div className="text-p_21 leading-normal">
       Using ZAL through the world's most popular cryptocurrency
       <span className="text-c-neutral-500">
        , wallet is expected to bring high value.
       </span>
      </div>
      <LinkWhitepaper>
       <Button color="neutral" className="w-fit">
        <div className="p_16">Read Whitepaper</div>
        <div className="w-s20 h-s20 flex justify-center items-center">
         <svg
          width="100%"
          height="100%"
          viewBox="0 0 25 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
         >
          <path
           d="M23.6719 8.79688L17.2969 15.1719C16.8281 15.6406 16.125 15.6406 15.7031 15.1719C15.2344 14.75 15.2344 14.0469 15.7031 13.5781L20.1562 9.125H1.125C0.46875 9.125 0 8.65625 0 8C0 7.39062 0.46875 6.875 1.125 6.875H20.1562L15.7031 2.42188C15.2344 2 15.2344 1.29688 15.7031 0.828125C16.125 0.40625 16.8281 0.40625 17.2969 0.828125L23.6719 7.25C24.0938 7.67188 24.0938 8.375 23.6719 8.79688Z"
           fill="currentColor"
          />
         </svg>
        </div>
       </Button>
      </LinkWhitepaper>
     </div>
     <Marquee
      gradient={true}
      speed={20}
      loop={0}
      gradientColor="#0e0e0e"
      gradientWidth={200}
     >
      {data.map((partner, index) => (
       <Partner key={index} item={partner} className="w-[12.69rem] mx-s6" />
      ))}
     </Marquee>
    </div>
   </div>
   <div className="container mt-s100">
    <div className="flex max-md:flex-col gap-y-s24 gap-x-s64">
     <Marquee
      gradient={true}
      speed={20}
      loop={0}
      gradientColor="#0e0e0e"
      gradientWidth={200}
     >
      {data.map((partner, index) => (
       <Partner key={index} item={partner} className="w-[12.69rem] mx-s6" />
      ))}
     </Marquee>
     <div className="max-w-[24.25rem] space-y-s32">
      <div className="text-p_21 leading-normal">
       ZAL Token is more than just a utility token
       <span className="text-c-neutral-500">
        ; it is the foundation for the Zalva Network vision
       </span>
      </div>
      <Button
       url="https://multiversx.com/ecosystem/partners-categories/onramps"
       color="neutral"
       className="w-fit"
      >
       <div color-mode="light" className="btn_bg" />
       <div className="p_16">Read Whitepaper</div>
       <div className="w-s20 h-s20 flex justify-center items-center">
        <svg
         width="100%"
         height="100%"
         viewBox="0 0 25 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
        >
         <path
          d="M23.6719 8.79688L17.2969 15.1719C16.8281 15.6406 16.125 15.6406 15.7031 15.1719C15.2344 14.75 15.2344 14.0469 15.7031 13.5781L20.1562 9.125H1.125C0.46875 9.125 0 8.65625 0 8C0 7.39062 0.46875 6.875 1.125 6.875H20.1562L15.7031 2.42188C15.2344 2 15.2344 1.29688 15.7031 0.828125C16.125 0.40625 16.8281 0.40625 17.2969 0.828125L23.6719 7.25C24.0938 7.67188 24.0938 8.375 23.6719 8.79688Z"
          fill="currentColor"
         />
        </svg>
       </div>
      </Button>
     </div>
    </div>
   </div>
  </section>
 );
}
