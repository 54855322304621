import { NumericFormat } from "react-number-format";
export const FormInput = ({
 value,
 onChange,
 placeholder,
 balance,
 tokenSymbol,
 logo,
 inject,
}) => {
 return (
  <div className="flex flex-col gap-2">
   <div className="w-full flex items-center px-1 justify-between">
    <p className="text-sm satoshi text-muted-foreground">From</p>
    <p className="text-sm satoshi cursor-pointer hover:text-white text-muted-foreground">
     Balance: {balance} {tokenSymbol}
    </p>
   </div>
   <div className="flex gap-2">
    {inject}
    <NumericFormat
     thousandSeparator
     placeholder={placeholder}
     value={value || ""}
     className="border-none w-full flex-1  text-white bg-[#161b1e]   py-3 px-2 rounded-lg"
     onValueChange={(e) => onChange?.(e.floatValue || 0)}
     disabled={!Boolean(onChange)}
    />
   </div>
  </div>
 );
};
