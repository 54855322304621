import React from "react";
import { cn } from "../../utils/helpers";

export default function Button({
 className,
 children,
 url,
 color = "green",
 ...rest
}) {
 const Component = url ? "a" : "button";

 return (
  <Component
   className={cn(
    "py-s10 px-s20 rounded-[0.75rem] flex justify-center items-center font-medium leading-[1.5] transition-all duration-150 gap-4 text-c-black-900 bg-c-dark-tial whitespace-nowrap",
    {
     "bg-c-neutral-800 hover:bg-c-neutral-700 text-white": color === "neutral",
     "bg-c-teal-400 hover:bg-c-teal-300 hover:shadow-btn text-c-black-900":
      color === "teal",
     "bg-c-green-400 hover:bg-c-green-300 text-c-black-900":
      color === "carbon-free",
     "bg-transparent !px-0 text-c-teal-400": color === "regular",
    },
    className
   )}
   href={url}
   {...rest}
  >
   {children}
  </Component>
 );
}
