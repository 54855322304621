import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
 const { pathname, hash } = useLocation();

 useEffect(() => {
  window.scrollTo(0, 0);
  if (hash) {
   const scrollId = hash.substring(1);

   if (scrollId) {
    findElement(scrollId).then((element) => {
     if (element) {
      element.scrollIntoView({ behavior: "smooth" });
     }
    });
   }
  }
 }, [pathname, hash]);

 return null;
}

const findElement = (elementId) => {
 let count = 0;
 return new Promise((resolve) => {
  const intervalId = setInterval(() => {
   const element = document.getElementById(elementId);
   if (element) {
    clearInterval(intervalId);
    resolve(element);
   } else {
    count++;
    if (count > 5) {
     clearInterval(intervalId);
     resolve(undefined);
    }
   }
  }, 500);
 });
};
