import React from "react";
import Badge from "../../components/ui/Badge";
import { cn } from "../../utils/helpers";

const items = [
 {
  title: "Native Assets",
  image: "/cdn/65b63c0583424289c1a53d71_esdt-visual.webp",
  contentClass: "mt-auto text-center",
  imgClass: "w-[16rem] left-16 sm:w-[90%] top-[-2.6rem] sm:left-4",
 },
 {
  title: "The Most Seamless User Experience",
  image: "/cdn/65ae87d79cf6d11431aac53f_glass%20shield.webp",
  contentClass: "text-center",
  imgClass: "w-[20rem] sm:w-[90%] bottom-[-2.6rem] left-4",
 },
 {
  title: "Maximum Developer Support",
  titleClass: "text-[2rem] md:text-[2.5rem] max-w-[20ch]",
  description:
   "Developers receive gas fee rewards every time someone calls their smart contract",
  image: "/cdn/65b12b9aa8fe7d6f25c09682_stacking-transition-visual.webp",
  contentClass: "md:flex-row justify-between gap-s24 md:items-end mt-auto",
  imgClass:
   "max-sm:top-[2rem] max-sm:opacity-60 max-sm:left-[51%] max-sm:right-auto max-md:top-[-6.1rem] max-lg:top-[-11.7rem] w-[120%] right-[-48%] top-[-5rem]",
  large: true,
 },
 {
  title: "Data Availability Cost",
  description: "Costs associated with storing transaction data on Layer 2",
  image: "/cdn/65b63c06dc53d03540a27fcb_state-double.webp",
  contentClass: "mt-auto",
  imgClass:
   "w-[133.91%] top-[-7.9rem] right-[-80%] max-sm:w-[25rem] max-sm:right-[-68%]",
 },
 {
  title: "Decentralization",
  description:
   "The level of decentralization of the Layer 2 network, including the number and distribution of validating nodes",
  image: "/cdn/65b63c06dc53d03540a27fcb_state-double.webp",
  contentClass: "mt-auto text-right items-end",
  imgClass:
   "w-[133.91%] top-[-7.9rem] left-[-56%] max-sm:w-[25rem] max-sm:left-[-49%]",
 },
 {
  title: "Fraud-Proof Mechanism",
  description: "Mechanism to detect and prevent fraudulent transactions",
  image: "/cdn/65b63ce139210ac96d9abef9_wasm.webp",
  contentClass: "text-center items-center mt-auto",
  imgClass: "blur-[5px] top-[-5rem] left-[-40%] w-[200%] object-cover h-[200%]",
 },
 {
  title: "Durable and Tested in a Sealed Environment",
  image: "/cdn/65b63c0539210ac96d9a5e70_metal-block.webp",
  contentClass: "",
  imgClass: "w-[120%] bottom-[-5.9rem] right-[-32%] max-sm:w-[22rem]",
 },
 {
  title: "Cross-Chain Support",
  description:
   "Create multi-chain applications and expand the scope of dApp operations",
  image: "/cdn/65b63c0463382a3b3006399d_fast-visual.webp",
  contentClass: "text-center items-center",
  imgClass:
   "absolute bottom-[-5.3rem] -left-16 w-[150%] max-sm:w-[30rem] max-sm:bottom-[-7.2rem]",
 },
 {
  title: "Zalva Network",
  image: "/cdn/65b63c067b861e9ce1800319_sidechains.webp",
  contentClass: "h-full flex justify-center items-end text-center",
  imgClass: "w-[150%] h-[150%] object-cover top-[-2rem] left-[-25%]",
  titleClass: "text-[3rem] lg:text-[5.25rem]",
  url: "https://multiversx.com/sovereign-chains",
  large: true,
 },
 {
  title: "Privacy Features",
  description: "Privacy protection features for users and transactions",
  image: "/cdn/65b6453116e06158e262c97d_validators-2.webp",
  contentClass: "text-center mt-auto items-center",
  imgClass: "w-[150%] top-4 left-[-25%]",
 },
];

export default function Builders() {
 return (
  <section className="builders pt-s100 pb-s80">
   <div className="container">
    <div className="flex flex-col items-center text-center gap-s16">
     <Badge>About Us</Badge>
     <div className="max-w-[85ch]">
      <h2 className="text-[2.25rem] md:text-[3rem] lg:text-[5.25rem] font-medium">
       We&#39;ll Make Your Decision Easy
      </h2>
     </div>
     <div className="text-p_21 text-c-neutral-500 leading-normal">
      Zalva Network provides the infrastructure you need to solve any use case.
     </div>
    </div>
    <div className="grid md:grid-cols-2 gap-s8 lg:grid-cols-4 mt-s80">
     {items.map((item, index) => {
      const Element = item.url ? "a" : "div";
      return (
       <Element
        key={index}
        className={cn(
         "max-md:px-s24 p-s40 relative overflow-hidden rounded-[2.5rem] min-h-[22.5rem] flex flex-col bg-c-neutral-900 border border-c-neutral-900",
         {
          "md:col-span-2": item.large,
         }
        )}
        href={item.url}
       >
        <img
         src={item.image}
         alt={item.title}
         className={cn(
          "absolute max-w-none pointer-events-none",
          item.imgClass
         )}
        />
        <div className={cn("flex flex-col gap-s4 relative", item.contentClass)}>
         <h3
          className={cn(
           "text-[1.5rem] leading-[1.2] font-medium",
           item.titleClass
          )}
         >
          {item.title}
         </h3>
         <p className="text-c-neutral-400 max-w-[25ch] leading-normal">
          {item.description}
         </p>
        </div>
       </Element>
      );
     })}
    </div>
   </div>
  </section>
 );
}
