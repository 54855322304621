import React from "react";
import FeatureCard from "../../../components/cards/FeatureCard";

const items = [
 {
  title: "Layer 2 Architecture",
  description:
   "Advanced Layer 2 architecture dramatically increases transaction throughput while reducing latency.",
  link: "/on-chain-2fa",
  linkText: "Read More",
  img: "/cdn/65af9ebfec6612fa3e048b5f_Dyson%20spheres03.webp",
 },
 {
  title: "Seamless Cross-Chain",
  description:
   "Enables seamless interoperability between multiple blockchain networks",
  link: "/builders/start-building",
  linkText: "Read More",
  img: "/cdn/65af9ebf74e20dabd82a3921_Dyson%20spheres02.webp",
 },
 {
  title: "Low Transaction Costs",
  description:
   "Zalva offers significantly lower transaction fees than Layer 1 solutions",
  link: "/releases",
  linkText: "Read More",
  img: "/cdn/65af9ec18ed5453dedd763a1_Dyson%20spheres01.webp",
 },
];

export default function WhatMake() {
 return (
  <section className="section pt-s100 pb-s200">
   <div className="container z-2">
    <div className="flex flex-col justify-center text-center items-center">
     <div className="max-w-[85ch]">
      <h2 className="text-[3rem] font-medium lg:text-[5.25rem]">
       What makes Zalva Network unique?
      </h2>
     </div>
     <div className="max-w-[50ch] mt-s16">
      <div className="text-p_21 text-c-neutral-500 leading-normal">
       Zalva Network stands out in the crowded blockchain space with its unique
       combination of features and innovative approach.
      </div>
     </div>
    </div>
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-s16 mt-s100">
     {items.map((item, index) => (
      <FeatureCard key={index} item={item} className="" />
     ))}
    </div>
   </div>
  </section>
 );
}
