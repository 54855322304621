"use client";

import { useData } from "fuhi-web3ts";
import React from "react";
import Marquee from "react-fast-marquee";
import { cn } from "../../utils/helpers";

export default function MarqueeCommunity({ className }) {
 const { data } = useData({
  path: "/data/communities.json",
  default: { left: [], right: [] },
 });
 return (
  <section className={cn("py-s100", className)}>
   <div className="">
    <div className="marquee-community">
     <Marquee className="items-stretch" direction="left" loop={0} speed={20}>
      {data.left.map((item, index) => (
       <Item item={item} key={index} />
      ))}
     </Marquee>
    </div>
    <div className="marquee-community mt-3">
     <Marquee className="items-stretch" direction="right" loop={0} speed={20}>
      {data.right.map((item, index) => (
       <Item item={item} key={index} />
      ))}
     </Marquee>
    </div>
   </div>
   <div className="container pt-s32">
    <h2 className="text-[1.5rem] md:text-[2.5rem] lg:text-[4rem]">
     Alone, we may only find a spark,
     <br />
     Together, we can ignite the flame.
    </h2>
   </div>
  </section>
 );
}

const Item = ({ item }) => (
 <img
  src={item}
  alt=""
  className="h-full rounded-[2.5rem] max-h-[80vw] md:max-h-[20rem] lg:max-h-[25rem] object-cover mx-1.5"
 />
);
