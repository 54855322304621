import React from "react";

export default function EasyToSetup() {
  return (
    <section className="section pt-s100">
      <div className="container">
        <div className="flex flex-col justify-center text-center items-center">
          <div className="max-w-[100ch]">
            <h2 className="text-[3rem] font-medium lg:text-[5.25rem]">
              Easy to setup. <br /> Advanced security.
            </h2>
          </div>
          <div className="max-w-[60ch] mt-s16">
            <div className="text-p_21 text-c-neutral-500 leading-normal">
            Zalva Network ensures anyone can get started quickly while still enjoying the highest level of protection.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
