import { useEffect, useMemo, useState } from "react";
import { ENV } from "../../../environment";
import "./clock.css";
export const Clock = () => {
 const [value, setValue] = useState({});

 useEffect(() => {
  let interval;
  const date = new Date(
   ENV.countdown.year,
   ENV.countdown.month - 1,
   ENV.countdown.day,
   ENV.countdown.hour,
   ENV.countdown.minute
  );
  if (date.getTime() > Date.now()) {
   interval = setInterval(() => {
    const distance = date.getTime() - Date.now(),
     day = Math.floor(distance / (1000 * 60 * 60 * 24)),
     hour = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
     minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
     second = Math.floor((distance % (1000 * 60)) / 1000);
    setValue({
     day,
     hour,
     minute,
     second,
    });
   }, 1000);
  }
  return () => {
   if (interval) clearInterval(interval);
  };
 }, []);
 return (
  <div className="flex gap-x-4">
   <Item text="D" value={value.day} />
   <Item text="H" value={value.hour} />
   <Item text="M" value={value.minute} />
   <Item text="S" value={value.second} />
  </div>
 );
};

const Item = ({ text, value }) => {
 const [value1, value2] = useMemo(() => {
  if (value == undefined || value === null) return ["-", "-"];
  if (value < 10) {
   return [0, value];
  }
  const vText = value + "";
  return vText;
 }, [value]);
 return (
  <div className="flex space-x-2">
   <div className="flex  lg:text-2xl font-semibold">
    {value1}
    {value2}
   </div>
   <DayOp text={text} />
  </div>
 );
};

const DayOp = ({ text }) => (
 <span className="text-xs lg:text-sm text-white/70 uppercase">{text}</span>
);
