import MarqueeCommunity from "../../components/layout/MarqueeCommunity";
import SideFeature from "../../components/layout/SideFeature";
import { ENV } from "../../environment";
import FAQs from "./FAQs";
import GetAndStore from "./GetAndStore";
import GetFamiliar from "./GetFamiliar";
import Hero from "./Hero";
import MarqueePartner from "./MarqueePartner";

const items = [
 {
  title: "Payment",
  description: (
   <>
    One of the key advantages of using ZAL Token for transactions is its low fee
    structure.
    <br />
    <br />
    Whether you are accessing a DeFi protocol, participating in a decentralized
    marketplace, or using any other dApp in the Zalva ecosystem, ZAL Tokens can
    be used as the primary currency for these services.
   </>
  ),
  reverse: true,
  ctas: [
   {
    text: "ZAL Token",
    href: "https://explorer.multiversx.com",
    type: "neutral",
   },
   {
    text: "Whitepaper",
    href: ENV.whitepaper,
    type: "regular",
   },
  ],
  img: "/cdn/65b6304a2f3e57f14bba7474_egld-30mil.webp",
 },
 {
  title: "Governance and Voting Rights",
  description: (
   <>
    ZAL Token holders can vote on important proposals related to protocol
    upgrades, network parameters, and other important decisions.
    <br />
    <br />
    By holding ZAL Tokens, community members can propose changes that can
    improve the network, fostering a dynamic and adaptive ecosystem.
   </>
  ),
  ctas: [],
  img: "/cdn/65b630b98518a835a646070e_egld-limit.webp",
 },
 {
  title: "Liquidity Provision and Yield Farming",
  description: (
   <>
    ZAL Token holders can provide liquidity to decentralized exchanges (DEXs)
    and other DeFi platforms within the Zalva ecosystem.
    <br />
    <br />
    Yield farming opportunities within the Zalva Network allow users to stake or
    lend their ZAL Tokens to earn additional rewards.
   </>
  ),
  reverse: true,
  ctas: [
   {
    text: "Stake ZAL",
    href: "/staking",
    type: "neutral",
   },
  ],
  img: "/cdn/65b631074a545184960fc095_egld-stake.webp",
 },
];

export default function EGLDPage() {
 return (
  <>
   <Hero />
   <GetFamiliar />
   {items.map((item, index) => (
    <SideFeature key={index} item={item} />
   ))}
   <GetAndStore />
   <MarqueePartner />
   <FAQs />
   <MarqueeCommunity />
  </>
 );
}
