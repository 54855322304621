import React from "react";
import { LinkWhitepaper } from "../../components/LinkCollection";
import Badge from "../../components/ui/Badge";
import Button from "../../components/ui/Button";

export default function Hero() {
 return (
  <>
   <img
    src="/cdn/65b12a02913368162d17128e_earn-visual.webp"
    alt=""
    className="max-w-none max-sm:top-[-1.7rem] w-[120%] absolute top-[-40rem] left-[-20%] rotate-[20deg] blur-[5px] z-[-1]"
   />
   <section className="hero relative overflow-hidden bg-c-black-900 rounded-[2.5rem]">
    <div className="bg-indv-stake-gradient sm:bg-indv-stake-gradient-sm absolute inset-0 z-[1]"></div>
    <div>
     <div className="absolute inset-0 z-0">
      <img
       src="/cdn/65aea1e6bad00585e1d03ceb_abstract%20shape%20-%20cube.webp"
       loading="eager"
       alt=""
       className="indv-stake_img-hero"
      />
      <img
       src="/cdn/65aea1e6ca9dd206e5c840ce_abstract%20shape%20-%20sphere.webp"
       loading="eager"
       alt=""
       className="indv-stake_img-hero is-2"
      />
      <img
       src="/cdn/65a63c1fff091fd7cdbfec8c_ecosystem_visual-3.webp"
       loading="eager"
       alt=""
       className="indv-stake_img-hero is-3"
      />
     </div>
     <div className="container relative z-[2]">
      <div className="flex flex-col items-center text-center pt-s300 md:pt-s200 pb-s80">
       <Badge
        bgClip={false}
        textClass="opacity-100"
        className="font-medium px-3 !py-0.5 leading-[0.88rem]"
       >
        Staking
       </Badge>

       <div className="max-w-[50ch] mt-s16">
        <h1 className="text-[2.5rem] md:text-[3.5rem] tracking-[-.01em] font-medium">
         Protect the chain, earn rewards
        </h1>
       </div>
       <div className="mt-s16 max-w-[70ch]">
        <div className="text-p_21 text-c-neutral-500">
         Delegate ZAL to validators or run your own node to provide economic
         security to the Zalva network and participate in governance voting.
        </div>
       </div>
       <div className="flex flex-wrap gap-s16 mt-s40">
        <Button
         url="/staking"
         color="teal"
         className="max-sm:w-full font-medium !px-s20"
        >
         Stake ZAL
         <div className="w-s20 h-s20 flex justify-center items-center">
          <svg
           width="100%"
           height="100%"
           viewBox="0 0 24 24"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
          >
           <path
            d="M24 1.5C24 6.84375 20.0156 11.25 14.8594 11.9062C14.5312 9.42188 13.4531 7.17188 11.8125 5.39062C13.5938 2.20312 17.0625 0 21 0H22.5C23.2969 0 24 0.703125 24 1.5ZM0 4.5C0 3.70312 0.65625 3 1.5 3H3C8.76562 3 13.5 7.73438 13.5 13.5V15V22.5C13.5 23.3438 12.7969 24 12 24C11.1562 24 10.5 23.3438 10.5 22.5V15C4.6875 15 0 10.3125 0 4.5Z"
            fill="currentColor"
           />
          </svg>
         </div>
        </Button>
        <LinkWhitepaper>
         <Button color="neutral" className="max-sm:w-full font-medium !px-s16">
          Read Whitepaper
          <div className="w-s20 h-s20 flex justify-center items-center">
           <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d="M3 0.5H21C22.6406 0.5 24 1.85938 24 3.5V6.5C24 8.1875 22.6406 9.5 21 9.5H3C1.3125 9.5 0 8.1875 0 6.5V3.5C0 1.85938 1.3125 0.5 3 0.5ZM16.125 3.875C15.4688 3.875 15 4.39062 15 5C15 5.65625 15.4688 6.125 16.125 6.125C16.7344 6.125 17.25 5.65625 17.25 5C17.25 4.39062 16.7344 3.875 16.125 3.875ZM18.375 5C18.375 5.65625 18.8438 6.125 19.5 6.125C20.1094 6.125 20.625 5.65625 20.625 5C20.625 4.39062 20.1094 3.875 19.5 3.875C18.8438 3.875 18.375 4.39062 18.375 5ZM3 12.5H21C22.6406 12.5 24 13.8594 24 15.5V18.5C24 20.1875 22.6406 21.5 21 21.5H3C1.3125 21.5 0 20.1875 0 18.5V15.5C0 13.8594 1.3125 12.5 3 12.5ZM16.125 15.875C15.4688 15.875 15 16.3906 15 17C15 17.6562 15.4688 18.125 16.125 18.125C16.7344 18.125 17.25 17.6562 17.25 17C17.25 16.3906 16.7344 15.875 16.125 15.875ZM18.75 17C18.75 17.6562 19.2188 18.125 19.875 18.125C20.4844 18.125 21 17.6562 21 17C21 16.3906 20.4844 15.875 19.875 15.875C19.2188 15.875 18.75 16.3906 18.75 17Z"
             fill="currentColor"
            />
           </svg>
          </div>
         </Button>
        </LinkWhitepaper>
       </div>
      </div>
     </div>
    </div>

    <div className="container relative z-[2] pb-s64">
     <div className="grid lg:grid-cols-12 gap-s8">
      <div className="lg:col-span-5 flex md:flex-col gap-s8 self-end items-end">
       <div className="bento_item w-full">
        <div className="bento-inner_vertical-24">
         <h2 className="text-c-neutral-500 font-medium">Total Staked</h2>
         <div className="">
          <div className="text-[2.5rem] lg:text-[3.5rem] flex items-center">
           <span>$--</span>
           <span className="opacity-60"> (--)</span>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div className="lg:col-span-3">
       <div className="bento_item w-full">
        <div className="bento-inner_vertical-24">
         <h2 className="text-c-neutral-500 font-medium">Users Staking</h2>
         <div className="ml-auto _100">
          <div className="text-[2.5rem] lg:text-[3.5rem] text-right">
           <span>--</span>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div className="lg:col-span-4">
       <div className="bento_item w-full">
        <div className="bento-inner_vertical-24">
         <h2 className="text-c-neutral-500 font-medium">Average APR</h2>
         <div className="ml-auto _100">
          <div className="text-[2.5rem] lg:text-[3.5rem] text-right">~128%</div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </section>
  </>
 );
}
