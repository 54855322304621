import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { loadEnv } from "./environment";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
const root = ReactDOM.createRoot(document.getElementById("root"));
loadEnv().then(() => root.render(<App />));

reportWebVitals();
