import React from "react";
import { cn } from "../../utils/helpers";

export default function Badge({ className, textClass, bgClip, children, tag }) {
 return (
  <div
   className={cn(
    "px-s12 py-s4 border border-[#00e6763d] bg-c-teal-black rounded-[31px] flex items-center",
    className
   )}
  >
   {tag && tag}
   <div
    className={cn(
     "leading-normal p-px flex items-center opacity-60",
     textClass,
     bgClip && "bg-clip-text bg-new-shine text-transparent"
    )}
   >
    {children}
   </div>
  </div>
 );
}
