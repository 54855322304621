import React from "react";

export default function GetAndStore() {
 return (
  <section className="get-and-store relative">
   <div className="container">
    <div className="absolute pointer-events-none inset-0 w-full h-full">
     <img
      src="/cdn/65b632600ee72a952f031c51_EGLD-Get-Section07.webp"
      loading="lazy"
      alt=""
      className="crystal-get is-7"
     />
     <img
      src="/cdn/65b632613037cbbc2a8b1814_EGLD-Get-Section06.webp"
      loading="lazy"
      alt=""
      className="crystal-get is-6"
     />
     <img
      src="/cdn/65b63262aef99f67e0fd0a2d_EGLD-Get-Section01.webp"
      loading="lazy"
      alt=""
      className="crystal-get"
     />
     <img
      src="/cdn/65b6326117c3ad76b124b6a6_EGLD-Get-Section02.webp"
      loading="lazy"
      alt=""
      className="crystal-get is-2"
     />
     <img
      src="/cdn/65b632624b8e99cd2d4acea1_EGLD-Get-Section03.webp"
      loading="lazy"
      alt=""
      className="crystal-get is-3"
     />
     <img
      src="/cdn/65b632610a4536d4fca3c491_EGLD-Get-Section04.webp"
      loading="lazy"
      alt=""
      className="crystal-get is-4"
     />
     <img
      src="/cdn/65b63262fc4c59f7f2b37243_EGLD-Get-Section05.webp"
      loading="lazy"
      alt=""
      className="crystal-get is-5"
     />
    </div>
    <div className="flex relative flex-col text-center items-center my-s200">
     <div className="mt-s24">
      <h2 className="max-w-[878px] text-[3rem] lg:text-[5.25rem] font-medium">
       How to Acquire ZAL Tokens?
      </h2>
     </div>
     <div className="max-w-[829px] mt-s16">
      <div className="text-p_21 text-c-neutral-500 leading-normal">
       Developers and community members can earn ZAL Tokens by contributing to
       the development and promotion of the Zalva Network.
      </div>
     </div>
    </div>
   </div>
  </section>
 );
}
