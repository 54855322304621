import { useData } from "fuhi-web3ts";
import { Link } from "../../components/Link";
import { ENV } from "../../environment";

export const News = () => {
 const { data } = useData({ path: "/data/news.json", default: [] });
 return (
  <div className="w-full news-bg flex items-center justify-center">
   <div className="max-w-[1792px] w-[calc(100%-36px)] lg:w-[calc(100%-128px)] flex flex-col items-center lg:mx-16 pt-20 lg:pt-40 pb-20 lg:pb-40">
    <div className="font-bold text-lg lg:text-4xl 3xl:text-6xl section-title-bg">
     News
    </div>
    <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-3 lg:gap-8 mt-[21px] lg:mt-[42px]">
     {data.map((item, idx) => (
      <Link
       key={idx}
       className="w-full group"
       data-aos="fade-up"
       target="_blank"
       to={item.href}
      >
       <div className="w-full h-[200px] lg:h-[370px] 3xl:h-[420px] flex flex-col items-start bg-[#141E21] group-hover:bg-[#FFFFFF1A] rounded-[8px] lg:rounded-3xl border border-[#FFFFFF1A] relative overflow-hidden">
        <div className="w-full h-[200px] relative">
         <img
          alt="news image"
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="object-cover"
          style={{
           position: "absolute",
           height: "100%",
           width: "100%",
           left: 0,
           top: 0,
           right: 0,
           bottom: 0,
           color: "transparent",
          }}
          src={item.image}
         />
        </div>
        <div className="flex flex-col items-start py-3 lg:py-0">
         <div className="text-white font-bold text-sm lg:text-base 3xl:text-xl px-3 lg:px-5 line-clamp-1 lg:line-clamp-2 lg:mt-5">
          {item.title}
         </div>
         <div className="hidden text-accent-content text-sm px-5 lg:line-clamp-3 mt-2">
          {item.description}
         </div>
         <div className="text-accent-content text-xs lg:text-sm px-3 lg:px-5 mt-1 lg:mt-6 lg:mb-3 lg:group-hover:hidden">
          {item.date}
         </div>
         <div className="w-full justify-center items-center text-[#1fefa3] gap-1 font-bold text-lg hidden mt-4 lg:mb-3 lg:group-hover:flex">
          Read{" "}
          <svg
           viewBox="0 0 28 28"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
           width={24}
           height={24}
          >
           <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.94 4.94a1.5 1.5 0 0 1 2.12 0l8 8a1.497 1.497 0 0 1-.005 2.127L17.06 23.06a1.5 1.5 0 0 1-2.122-2.122l5.44-5.439H4a1.5 1.5 0 0 1 0-3h16.379l-5.44-5.44a1.5 1.5 0 0 1 0-2.12Z"
            fill="currentColor"
           />
          </svg>
         </div>
        </div>
       </div>
      </Link>
     ))}
    </div>
    <Link
     className="w-full lg:w-auto mt-3 lg:mt-9"
     to={ENV.whitepaper}
     data-aos="fade-up"
    >
     <div className="w-full lg:w-auto h-8 lg:h-12 flex items-center justify-center text-[#1fefa3] gap-1 lg:gap-2 font-semibold lg:font-bold text-xs lg:text-base px-8 rounded-[8px] border border-white/5 bg-[#1F2A2E99]">
      Read all news
      <div>
       <svg
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
       >
        <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M14.94 4.94a1.5 1.5 0 0 1 2.12 0l8 8a1.497 1.497 0 0 1-.005 2.127L17.06 23.06a1.5 1.5 0 0 1-2.122-2.122l5.44-5.439H4a1.5 1.5 0 0 1 0-3h16.379l-5.44-5.44a1.5 1.5 0 0 1 0-2.12Z"
         fill="currentColor"
        />
       </svg>
      </div>
     </div>
    </Link>
   </div>
  </div>
 );
};
