import { myToast } from "fuhi-web3ts";
import React, { useState } from "react";
import Button from "../ui/Button";

export default function Newsletter() {
 const [email, setEmail] = useState();
 return (
  <div className="container relative">
   <div className="container_bg pointer-events-none">
    <img
     src="/images/bg-02.webp"
     className="absolute max-sm:top-auto max-sm:-left-1/2 max-sm:bottom-[-2.8rem] max-lg:top-[-2.6rem] lg:top-[-5.6rem] object-contain left-[-42%] w-[61.17%]"
     width={707}
     height={527}
     alt="bg"
    />
    <img
     src="/images/bg-02.webp"
     className="absolute max-sm:top-[-4rem] max-sm:w-full object-contain max-lg:top-[-2.6rem] lg:top-[-5.6rem] right-[-42%] w-[61.17%] rotate-[60deg]"
     width={707}
     height={527}
     alt="bg"
    />
   </div>
   <div className="max-sm:px-[5vw] relative pt-s200 lg:pt-s100 pb-s100">
    <div className="flex items-center justify-center flex-col text-center mx-auto max-w-[77ch] mb-[15px]">
     <div className="lg:max-w-[65ch] mb-s24">
      <h2 className="text-2xl">
       Always stay updated and never miss any updates
      </h2>
     </div>
     <div className="spacer_24" />
     <form id="email-form" method="post" className="w-full newsletter_form">
      <div className="flex justify-center items-center w-full gap-s8">
       <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="form_input w-input w-full"
        maxLength={256}
        name="Email"
        data-name="Email"
        placeholder="Enter a valid email address"
        type="email"
        id="Email"
        required
       />
       <Button
        type="submit"
        className=""
        onClick={() => {
         if (!email) {
          myToast.toast.error("Please enter your email address");
          return;
         }
         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         if (!emailRegex.test(email)) {
          myToast.toast.error("Please enter a valid email address");
          return;
         }
         myToast.toast.success("Thank you for subscribing!");
         setEmail("");
        }}
       >
        Subscribe Now
       </Button>
      </div>
     </form>

     <div className="pt-s24 max-w-[48ch]">
      <p className="text-p_14 text-c-neutral-500 leading-[1.3]">
       We are committed to protecting your privacy. Zalva Network uses the
       information you provide to contact you about Zalva Network content and
       events. You can unsubscribe from these communications at any time.
      </p>
     </div>
    </div>
   </div>
  </div>
 );
}
