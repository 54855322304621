import React from "react";
import FAQList from "../../components/layout/FAQList";

const items = [
 {
  title: "What is Zalva Network?",
  content: (
   <>
    <p>
     Zalva Network is an advanced Layer 2 blockchain solution designed to
     enhance the scalability, security, and interoperability of decentralized
     applications (dApps) across multiple blockchain networks. Built with
     cutting-edge technology, Zalva aims to address the limitations of existing
     Layer 1 blockchains, such as high transaction fees, slow processing times,
     and limited scalability.
    </p>
   </>
  ),
 },
 {
  title: "How to achieve high scalability?",
  content: (
   <>
    <p>
     Zalva Network achieves high scalability through its Layer 2 architecture,
     which is designed to offload a significant portion of transaction
     processing from the underlying Layer 1 blockchain. This approach allows
     Zalva to handle much higher transaction volumes per second (TPS) while
     maintaining low latency and cost efficiency.
    </p>
   </>
  ),
 },
 {
  title: "What is Cross-Chain Interoperability?",
  content: (
   <>
    <p>
     Cross-chain interoperability refers to the ability of different blockchain
     networks to interact and communicate with each other, allowing assets,
     data, and smart contracts to move seamlessly across chains. This ability is
     critical to creating a more connected and efficient blockchain ecosystem,
     where users and developers can take advantage of the unique features of
     multiple blockchains without being limited to a single network.
    </p>
   </>
  ),
 },
];

export default function FAQs() {
 return (
  <FAQList
   title="Frequently Asked Questions"
   subtitle="Learn about Zalva Network"
   items={items}
  />
 );
}
