import ScrollToTop from "./components/ScrollToTop.jsx";
import "./styles/globals.scss";
import "./styles/index.scss";

import AOS from "aos";
import "aos/dist/aos.css";

import { Web3TsProvider, bsc } from "fuhi-web3ts";
import { useEffect } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import { ENV } from "./environment";
import { PresalePage } from "./pages/buy/page.jsx";
import EGLDPage from "./pages/egld/page.js";
import HomePage from "./pages/home/page";
import GetStartedPage from "./pages/individuals/get-started/page";
import OnChain2FAPage from "./pages/on-chain-2fa/page";
import StakingPage from "./pages/staking/page";
const Layout = () => {
 return (
  <>
   <Header />
   <main className="flex min-h-screen relative flex-col w-full overflow-hidden p-s4 lg:p-s8">
    <Outlet />
   </main>
   <Footer />
   <ScrollToTop />
  </>
 );
};

const router = createBrowserRouter([
 {
  path: "/",
  element: <Layout />,
  children: [
   {
    path: "zal-token",
    element: <EGLDPage />,
   },
   {
    path: "/",
    index: true,
    element: <HomePage />,
   },
   { path: "staking", element: <StakingPage /> },
   { path: "get-started", element: <GetStartedPage /> },
   { path: "cross-chain", element: <OnChain2FAPage /> },
   {
    path: "presale",
    element: <PresalePage />,
   },
  ],
 },
]);
function App() {
 useEffect(() => {
  AOS.init({
   duration: 500,
  });
 }, []);
 return (
  <Web3TsProvider
   config={{
    buyContract: {
     contractAddress: ENV.buyContractAddress,
     buyMethod: "Presale",
     buyUSDTMethod: "PresaleWithUSDT",
     referralPath: ENV.referralPath,
     disableBuy: ENV.disableBuy === true,
    },
    mainContract: {
     contractAddress: ENV.contractAddress,
     logo: window.location.origin + "/logo4wallet.png",
     symbol: ENV.tokenSymbol,
    },
    chains: [bsc],
    metadata: {
     url: window.location.origin,
     ...ENV.metadata,
     icons: [window.location.origin + "/logo4wallet.png"],
    },
    projectId: "3769acab4b07f7216940d68d8e4ca59f",
    walletIds: [
     "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    ],
   }}
  >
   <ToastContainer hideProgressBar position="bottom-left" />
   <RouterProvider router={router} />
  </Web3TsProvider>
 );
}

export default App;
