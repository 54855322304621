import React from "react";
import { LinkWhitepaper } from "../../components/LinkCollection";
import Badge from "../../components/ui/Badge";
import Button from "../../components/ui/Button";

export default function Hero() {
 return (
  <>
   <img
    src="/cdn/659c0706e77100ec0ebec81c_Hero%20section%20background.webp"
    alt=""
    className="max-w-none max-sm:top-[-1.7rem] w-[140%] absolute top-[-30rem] left-[-20%] rotate-[20deg] blur-[5px] z-[-1]"
   />
   <section className="hero relative overflow-hidden bg-c-black-900 rounded-[2.5rem]">
    <div className="bg-2fa-gradient sm:2fa-gradient-sm lg:bg-2fa-gradient-lg absolute inset-0 z-[1]"></div>
    <div>
     <div className="absolute inset-0 z-0">
      <img
       src="/cdn/65ae87d5f7259f0356435236_glass%20lock.webp"
       alt=""
       className="indv-chain_img-hero"
      />
      <img
       src="/cdn/65ae87d79cf6d11431aac53f_glass%20shield.webp"
       alt=""
       className="indv-chain_img-hero is-2"
      />
      <img
       src="/cdn/659c0890d7f4a89b66aedea9_Home%20Hero%20Bg%2001.webp"
       alt=""
       className="indv-chain_img-hero is-3"
      />
     </div>
     <div className="container relative z-[2]">
      <div className="flex flex-col items-center text-center pt-s300 md:pt-s200 pb-s80">
       <div className="relative mt-s20 md:mt-s10">
        <Badge
         bgClip={false}
         textClass="opacity-100"
         className="font-medium px-3 !py-0.5 leading-[0.88rem]"
        >
         Cross-chain Solution
        </Badge>
        <div class="power_outline-blue">
         <div color-mode="light" class="btn-power">
          <div class="text-p_14 power_text-gradient">
           Get Started on Zalva Network
          </div>
         </div>
        </div>
       </div>

       <div className="max-w-[85ch] mt-s16">
        <h1 className="text-[2.5rem] md:text-[3.5rem] tracking-[-.01em] font-medium">
         Unlocking Interoperability Across Blockchains
        </h1>
       </div>
       <div className="mt-s16 max-w-[75ch]">
        <div className="text-p_21 text-c-neutral-500">
         Zalva Network's Cross-Chain solution is designed to seamlessly connect
         different blockchains, allowing smooth interaction between different
         ecosystems.
        </div>
       </div>
       <div className="flex max-sm:flex-col max-sm:w-full gap-s16 mt-s40">
        <Button
         url="https://www.youtube.com/watch?v=qrTKehOwwpY"
         color="teal"
         className="max-sm:w-full font-medium !px-s20"
        >
         ZAL Token
        </Button>
        <LinkWhitepaper>
         <Button color="neutral" className="max-sm:w-full font-medium !px-s16">
          Whitepaper
          <div className="w-s16 h-s16 flex justify-center items-center">
           <svg
            width="100%"
            height="100%"
            viewBox="0 0 16 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d="M7.20312 23.6719L0.828125 17.2969C0.359375 16.875 0.359375 16.1719 0.828125 15.7031C1.25 15.2812 1.95312 15.2812 2.42188 15.7031L6.875 20.2031V1.125C6.875 0.515625 7.34375 0 8 0C8.60938 0 9.125 0.515625 9.125 1.125V20.2031L13.5781 15.75C14 15.2812 14.7031 15.2812 15.1719 15.75C15.5938 16.1719 15.5938 16.875 15.1719 17.2969L8.75 23.6719C8.32812 24.1406 7.625 24.1406 7.20312 23.6719Z"
             fill="currentColor"
            />
           </svg>
          </div>
         </Button>
        </LinkWhitepaper>
       </div>
      </div>
     </div>
    </div>

    <div className="container relative z-[2] pb-s64">
     <div className="grid lg:grid-cols-12 gap-s8">
      <div className="lg:col-span-5 flex md:flex-col gap-s8 self-end items-end">
       <div className="bento_item w-full">
        <div className="bento-inner_vertical-24">
         <h2 className="text-c-neutral-500 font-medium">
          Whether you are handling assets on Ethereum, BNB Chain or any other
          supported chain
         </h2>
         <div className="ml-auto _100">
          <div className="text-[2.5rem] lg:text-[3.5rem] text-right">
           <span>Bridging Multiple Chains</span>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div className="lg:col-span-4">
       <div className="bento_item w-full">
        <div className="bento-inner_vertical-24">
         <h2 className="text-c-neutral-500 font-medium">
          Our Cross-Chain solution allows transferring assets across different
          chains
         </h2>
         <div className="ml-auto _100">
          <div className="text-[6vw] md:text-[2.5rem] lg:text-[3.5rem] text-right">
           Seamless<span className="opacity-60"> Transfers</span>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div className="lg:col-span-3">
       <div className="bento_item w-full">
        <div className="bento-inner_vertical-24">
         <h2 className="text-c-neutral-500 font-medium">
          Security is paramount when dealing with cross-chain transactions
         </h2>
         <div className="ml-auto _100">
          <div className="text-[2.5rem] lg:text-[3.5rem] text-right">
           Security
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </section>
  </>
 );
}
