import React from "react";
import { LinkPresale, LinkWhitepaper } from "../../components/LinkCollection";
import Badge from "../../components/ui/Badge";
import Button from "../../components/ui/Button";

const tools = [
 {
  name: "Google Cloud",
  logo: "/cdn/65b8dcfffbdd63306039d837_google-cloud-logo.svg",
 },
 {
  name: "Telekom",
  logo: "/cdn/65b633a9c3edfa5897b93d61_Telekom.svg",
 },
 {
  name: "Tencent Cloud",
  logo: "/cdn/65b633a9bcfbf52a02e3e92c_tencent-cloud.svg",
 },
 {
  name: "AWS",
  logo: "/cdn/65b633a9fe1ff665b88581c1_logo-aws.svg",
 },
 {
  name: "AXELAR",
  logo: "/cdn/65b633a951432c9ff5b60a35_AXELAR.svg",
 },
 {
  name: "LEDGER",
  logo: "/cdn/65ba4a95f251bd79dcba38c7_Ledger.svg",
 },
 {
  name: "Opera",
  logo: "/cdn/65b633a9fa89fdf13c16a913_Opera.svg",
 },
];

export default function Hero() {
 return (
  <>
   <img
    src="/cdn/659c0706e77100ec0ebec81c_Hero%20section%20background.webp"
    alt=""
    className="max-w-none max-sm:top-[-1.7rem] w-[140%] absolute top-[-30rem] left-[-20%] rotate-[20deg] blur-[5px] z-[-1]"
   />
   <section className="hero relative overflow-hidden bg-c-black-900 rounded-[2.5rem] my-1">
    <div className="bg-hero-gradient sm:bg-hero-gradient-sm lg:bg-hero-gradient-lg absolute inset-0 z-[1]"></div>
    <div>
     <div className="absolute inset-0 z-0">
      <img
       src="/cdn/659c0890d7f4a89b66aedea9_Home%20Hero%20Bg%2001.webp"
       alt=""
       className="hero_bg-image"
      />
      <img
       src="/cdn/659c08906102d36e42281d15_Home%20Hero%20Bg%2003.webp"
       alt=""
       className="hero_bg-image is-2"
      />
      <img
       src="/cdn/65ae7d564c5383698df27957_Glass%20shield%404-1080x1080%201.webp"
       loading="eager"
       alt=""
       className="hero_bg-image is-3"
      />
     </div>
     <div className="container relative z-[2]">
      <div className="flex flex-col items-center text-center pt-s300 md:pt-s200 pb-s100">
       <a
        href="/sovereign-chains"
        className="btn outline-green w-inline-block group"
       >
        <Badge
         tag={
          <span className="bg-c-teal-400 rounded-[1.75rem] mr-s8 group-hover:mr-s16 transition-all text-c-black-1000 text-p_10 leading-[1rem] font-bold px-1">
           NEW
          </span>
         }
         className="text-p_14 pl-1 pr-2 !pt-0 !pb-0.5 leading-[0.88rem]"
        >
         ZAL Token is coming
        </Badge>
       </a>

       <h1 className="text-[2.5rem] md:text-[4rem] mt-s16">
        Speed and Security, Perfected
       </h1>
       <div className="mt-s24 max-w-[90ch]">
        <div className="text-p_21 text-c-neutral-500">
         Zalva Network is not just a Layer 2 solution, but a leap forward in the
         development of blockchain technology. We bring innovation by providing
         a scalable, secure and cost-effective platform for any decentralized
         application (dApp).
        </div>
       </div>
       <div className="flex flex-wrap gap-s16 mt-s40">
        <LinkPresale>
         <Button color="neutral" className="max-sm:w-full">
          ZAL Token
          <div className="w-s20 h-s20 flex justify-center items-center">
           <svg
            width={31}
            height={30}
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d="M20 25.3594L11 22.7812V4.6875L20 7.26562V25.3594ZM21.5 25.2656V7.17188L27.4531 4.78125C28.1562 4.5 29 5.01562 29 5.8125V21.5156C29 21.9844 28.7188 22.4062 28.25 22.5469L21.5 25.2656ZM2.70312 7.5L9.5 4.78125V22.875L3.5 25.2656C2.79688 25.5469 2 25.0312 2 24.2344V8.53125C2 8.0625 2.28125 7.64062 2.70312 7.5Z"
             fill="#F5F5F5"
            />
           </svg>
          </div>
         </Button>
        </LinkPresale>
        <LinkWhitepaper>
         <Button
          url="https://multiversx.com/builders-hub"
          color="teal"
          className="max-sm:w-full"
         >
          Whitepaper
          <div className="w-s20 h-s20 flex justify-center items-center">
           <svg
            width="100%"
            height="100%"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d="M21.3594 14.1562C20.0469 14.3906 18.6406 13.9688 17.6094 12.9844L15.8281 11.1562C15.125 10.4531 14.75 9.51562 14.75 8.53125V7.96875L11 5.90625C10.7656 5.8125 10.5781 5.53125 10.625 5.25C10.625 4.96875 10.7656 4.6875 11.0469 4.59375L13.25 3.60938C14.1406 3.23438 15.0781 3 16.0156 3H16.8594C18.5938 3 20.2344 3.65625 21.5 4.875L23.5625 6.84375C24.6875 7.875 25.1094 9.42188 24.8281 10.875L25.5781 11.5781L25.9531 11.2031C26.375 10.7812 27.0781 10.7812 27.5 11.2031L28.625 12.3281C29.0938 12.7969 29.0938 13.5 28.625 13.9219L24.5 18.0469C24.0781 18.5156 23.375 18.5156 22.9531 18.0469L21.8281 16.9219C21.3594 16.5 21.3594 15.7969 21.8281 15.3281L22.2031 14.9531L21.3594 14.1562ZM3.26562 20.7188L14.1875 11.5781C14.375 11.8125 14.5625 12.0469 14.75 12.2344L16.5312 14.0156C16.8125 14.2969 17.1406 14.5312 17.4688 14.7656L8.28125 25.7344C7.625 26.5312 6.59375 27 5.5625 27C3.59375 27 2 25.4062 2 23.4375C2 22.4062 2.46875 21.375 3.26562 20.7188Z"
             fill="currentColor"
            />
           </svg>
          </div>
         </Button>
        </LinkWhitepaper>
       </div>
      </div>
     </div>
    </div>

    <div className="container relative z-[2]">
     <div className="grid lg:grid-cols-12 gap-s8">
      <div className="lg:col-span-5 flex md:flex-col gap-s8 self-end items-end">
       <div className="bento_item max-md:w-1/2 lg:w-fit">
        <div className="bento-inner_horizontal-right-24">
         <div className="md:max-w-[15ch]">
          <h2 className="text-c-neutral-500 font-medium">
           Average Cost per Transaction
          </h2>
         </div>
         <div className="text-right">
          <div className="text-[6vw] md:text-[2rem] lg:text-[2.5rem]">
           ~$0<span className="opacity-60">.00</span>
           <span count="static" className="opacity-60">
            5
           </span>
          </div>
         </div>
        </div>
       </div>
       <div className="bento_item max-md:w-1/2 w-full">
        <div className="bento-inner_vertical-24">
         <h2 className="text-c-neutral-500 font-medium">Transactions</h2>
         <div className="ml-auto _100">
          <div className="text-[6vw] md:text-[2.5rem] lg:text-[3.5rem] text-right">
           <span>200,000+</span>
          </div>
         </div>
        </div>
        <div className="bento_curtain" />
       </div>
      </div>
      <div className="lg:col-span-4 flex">
       <div className="bento_item is--validator mt-auto w-full">
        <div className="bento-inner_vertical-24">
         <h2 className="lg:mt-s80 text-c-neutral-500 font-medium">
          Validator Nodes
         </h2>
         <div className="ml-auto _100">
          <div className="text-[2rem] md:text-[3.5rem]">10,000+</div>
         </div>
        </div>
        <img
         src="/cdn/65b3b25ee767cca97aa61623_validator%20map.webp"
         alt="validators world map"
         className="pointer-events-none absolute inset-0"
        />
       </div>
      </div>
      <div className="bento_item lg:col-span-3 carbon-free">
       <img
        src="/cdn/65ae99ad1dd1d64948bd8724_carbon-free_planet.webp"
        alt=""
        className="absolute max-w-none w-[120%] right-[-55%] z-0 rotate-[150deg]"
       />
       <div className="f_vertical-left relative">
        <h2 className="text-c-neutral-500 font-medium">
         Latency. Scalability. Security
        </h2>
        <div className="text-[2rem] md:text-[2.5rem] mt-s80 mb-s16">
         Read Whitepaper
        </div>
        <Button
         color="carbon-free"
         url="https://environment.multiversx.com/"
         className="w-fit px-s8 py-s6 rounded-[0.38rem] gap-s8"
         rel="noopener noreferrer"
        >
         <div className="text-p_14">Sustainability</div>
         <div className="w-s10 h-s10 w-embed">
          <svg
           width="100%"
           height="100%"
           viewBox="0 0 8 8"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
          >
           <path
            d="M7.1875 0C7.49219 0 7.75 0.257812 7.75 0.5625V6.1875C7.75 6.51562 7.49219 6.75 7.1875 6.75C6.85938 6.75 6.625 6.51562 6.625 6.1875V1.92188L1.21094 7.33594C0.976562 7.57031 0.625 7.57031 0.414062 7.33594C0.179688 7.125 0.179688 6.77344 0.414062 6.5625L5.82812 1.14844H1.5625C1.23438 1.14844 1 0.890625 1 0.585938C1 0.257812 1.23438 0.0234375 1.5625 0.0234375H7.1875V0Z"
            fill="currentColor"
           />
          </svg>
         </div>
        </Button>
       </div>

       <div className="bento_curtain" />
      </div>
     </div>
    </div>

    <div className="container relative z-[2] pt-s32 pb-s60">
     <div className="opacity-40 text-center">
      Integrations and tools from leading global partners in the Blockchain
      space
     </div>
     <div className="flex flex-wrap justify-center gap-x-s40 gap-y-s24 mt-s32">
      {tools.map((item, index) => (
       <div key={index} className="home_partner-item">
        <img
         src={item.logo}
         alt={item.name}
         className="max-w-[9.3rem] max-h-[2rem]"
        />
       </div>
      ))}
     </div>
    </div>
   </section>
  </>
 );
}
