"use client";

import React, { useRef, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { cn } from "../../utils/helpers";

export default function Slider({ title, className, items, renderItem }) {
 const sliderRef = useRef(null);
 const [activeIndex, setActiveIndex] = useState(0);

 function handlePrev() {
  sliderRef.current?.swiper.slidePrev();
 }

 function handleNext() {
  sliderRef.current?.swiper.slideNext();
 }

 return (
  <section className="pt-s80 pb-s40 relative z-0">
   <div className="container">
    <div className="flex flex-wrap gap-s16 justify-between items-center">
     <h2 className="text-[1.63rem]">{title}</h2>
     <div className="flex gap-3">
      <button
       aria-label="Previous slide"
       className={cn(
        "p-s6 border border-c-neutral-800 text-c-neutral-200 flex justify-center items-center transition-colors hover:bg-c-neutral-800 rounded-full w-10 h-10",
        {
         "opacity-60": activeIndex === 0,
        }
       )}
       disabled={activeIndex === 0}
       onClick={handlePrev}
      >
       <div className="w-s20 h-s20 aspect-square">
        <svg
         width="100%"
         height="100%"
         viewBox="0 0 20 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
        >
         <path
          d="M6.28125 9.3125L11.2812 4.3125C11.6562 3.90625 12.3125 3.90625 12.6875 4.3125C13.0938 4.6875 13.0938 5.34375 12.6875 5.71875L8.40625 10L12.6875 14.3125C13.0938 14.6875 13.0938 15.3438 12.6875 15.7188C12.3125 16.125 11.6562 16.125 11.2812 15.7188L6.28125 10.7188C5.875 10.3438 5.875 9.6875 6.28125 9.3125Z"
          fill="currentColor"
         />
        </svg>
       </div>
      </button>
      <button
       aria-label="Next slide"
       onClick={handleNext}
       className={cn(
        "p-s6 border border-c-neutral-800 text-c-neutral-200 flex justify-center items-center transition-colors hover:bg-c-neutral-800 rounded-full w-10 h-10",
        {
         "opacity-60": activeIndex === items.length - 1,
        }
       )}
       disabled={activeIndex === items.length - 1}
      >
       <div className="w-s20 h-s20 aspect-square">
        <svg
         width="100%"
         height="100%"
         viewBox="0 0 20 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
        >
         <path
          d="M13.6875 9.3125C14.0938 9.6875 14.0938 10.3438 13.6875 10.7188L8.6875 15.7188C8.3125 16.125 7.65625 16.125 7.28125 15.7188C6.875 15.3438 6.875 14.6875 7.28125 14.3125L11.5625 10L7.28125 5.71875C6.875 5.34375 6.875 4.6875 7.28125 4.3125C7.65625 3.90625 8.3125 3.90625 8.6875 4.3125L13.6875 9.3125Z"
          fill="currentColor"
         />
        </svg>
       </div>
      </button>
     </div>
    </div>
    <div className="mt-s32">
     <Swiper
      className={className}
      onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      ref={sliderRef}
      spaceBetween={16}
      breakpoints={{
       480: {
        slidesPerView: 1,
       },
       768: {
        slidesPerView: 3,
       },
       992: {
        slidesPerView: 5,
       },
      }}
     >
      {items.map((item, index) => (
       <SwiperSlide key={index}>{renderItem(item)}</SwiperSlide>
      ))}
     </Swiper>
    </div>
   </div>
  </section>
 );
}
