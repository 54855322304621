import MarqueeCommunity from "../../../components/layout/MarqueeCommunity";
import Planet from "../../../components/layout/Planet";
import Hero from "../../on-chain-2fa/Hero";
import GetStarted from "./GetStarted";
import WhatMake from "./WhatMake";

export default function GetStartedPage() {
 return (
  <>
   <Hero />
   <WhatMake />
   <GetStarted />
   <Planet />
   <MarqueeCommunity className="mt-s200" />
  </>
 );
}
