import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export function Link(props) {
 const { to } = props;
 const { search } = useLocation();
 const navigate = useNavigate();
 const handleClick = (e) => {
  props.onClick?.();
  e.preventDefault();
  if (to) {
   if (to.includes("http") || to.includes("mailto")) {
    const a = document.createElement("a");
    a.href = to;
    a.target = "_blank";
    a.click();
   } else {
    navigate(props.to + `${search}`);
   }
  } else {
   toast.info("Coming soon!");
  }
 };
 return (
  <a
   href={to}
   className={`cursor-pointer ${props.underline ? "hover:underline" : ""} ${
    props.className || ""
   }`}
   onClick={handleClick}
  >
   {props.children}
  </a>
 );
}
