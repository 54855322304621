import React from "react";
import { ENV } from "../../environment";
import { Link } from "../Link";
import Newsletter from "./Newsletter";
const menuLinks = [
 {
  title: "Home",
  link: "/",
 },
 {
  title: "Get Started",
  link: "/get-started",
 },
 {
  title: "ZAL Token",
  link: "/zal-token",
 },
 {
  title: "Cross-chain",
  link: "/cross-chain",
 },
 {
  title: "Stake",
  link: "/staking",
 },
];

export default function Footer() {
 const socialLinks = [
  {
   name: "X",
   url: ENV.socials.x,
   icon: (
    <svg
     width="100%"
     height="100%"
     viewBox="0 0 30 30"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path
      d="M21.2344 5.25H24.5156L17.2969 13.5469L25.8281 24.75H19.1719L13.9219 17.9531L7.96875 24.75H4.64062L12.375 15.9375L4.21875 5.25H11.0625L15.75 11.4844L21.2344 5.25ZM20.0625 22.7812H21.8906L10.0781 7.125H8.10938L20.0625 22.7812Z"
      fill="currentColor"
     />
    </svg>
   ),
  },
  {
   name: "Telegram",
   url: ENV.socials.telegram,
   icon: (
    <svg
     width="100%"
     height="100%"
     viewBox="0 0 30 30"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path
      d="M15 3.375C21.375 3.375 26.625 8.625 26.625 15C26.625 21.4219 21.375 26.625 15 26.625C8.57812 26.625 3.375 21.4219 3.375 15C3.375 8.625 8.57812 3.375 15 3.375ZM20.3438 11.2969C20.3906 11.1562 20.3906 11.0156 20.3438 10.8281C20.3438 10.7344 20.25 10.5938 20.2031 10.5469C20.0625 10.4062 19.8281 10.4062 19.7344 10.4062C19.3125 10.4062 18.6094 10.6406 15.375 12C14.25 12.4688 12 13.4062 8.625 14.9062C8.0625 15.1406 7.78125 15.3281 7.73438 15.5625C7.6875 15.9375 8.29688 16.0781 9 16.3125C9.60938 16.5 10.4062 16.7344 10.8281 16.7344C11.2031 16.7344 11.625 16.5938 12.0938 16.2656C15.2344 14.1094 16.875 13.0312 16.9688 13.0312C17.0625 13.0312 17.1562 12.9844 17.2031 13.0312C17.2969 13.125 17.2969 13.2188 17.25 13.2656C17.2031 13.5 14.25 16.2188 14.0625 16.4062C13.4062 17.0625 12.6562 17.4844 13.8281 18.2344C14.8125 18.8906 15.375 19.3125 16.4062 19.9688C17.0625 20.3906 17.5781 20.9062 18.2344 20.8594C18.5625 20.8125 18.8906 20.5312 19.0312 19.6406C19.4531 17.625 20.2031 13.125 20.3438 11.2969Z"
      fill="currentColor"
     ></path>
    </svg>
   ),
  },
 ];
 return (
  <div className="px-s4 lg:px-s8 py-s8">
   <div className="bg-c-black-900 rounded-[1.5rem] overflow-hidden">
    <Newsletter />

    <footer className="pt-s100">
     <div className="container">
      <div className="grid gap-s56 justify-between items-start lg:grid-cols-5">
       <div className="">
        <a aria-label="Home" href="#" className="nav_logo-link w-inline-block">
         <img
          src="/logos/MultiversX-Logo.svg"
          alt="MultiversX"
          width={136}
          height={22}
          className="mvx_logo"
         />
        </a>
        <div className="pt-s50 flex gap-s16">
         {socialLinks.map((link, index) => (
          <a
           key={index}
           aria-label={link.name}
           href={link.url}
           target="_blank"
           className="w-s30 h-s30 flex justify-center items-center text-c-neutral-300 hover:text-c-teal-400 transition-colors"
           rel="noopener noreferrer"
          >
           <div className="">{link.icon}</div>
          </a>
         ))}
        </div>
        <div className="pt-[1em]">
         <a
          href="https://linktr.ee/multiversx"
          target="_blank"
          className="flex items-center gap-s4 text-c-neutral-500 transition-colors hover:text-c-neutral-300"
          rel=" noopener noreferrer"
         >
          <div className="text-[0.88rem]">Official Channels</div>
          <div className="w-s10 h-s10">
           <svg
            width="100%"
            height="100%"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d="M7.1875 0C7.49219 0 7.75 0.257812 7.75 0.5625V6.1875C7.75 6.51562 7.49219 6.75 7.1875 6.75C6.85938 6.75 6.625 6.51562 6.625 6.1875V1.92188L1.21094 7.33594C0.976562 7.57031 0.625 7.57031 0.414062 7.33594C0.179688 7.125 0.179688 6.77344 0.414062 6.5625L5.82812 1.14844H1.5625C1.23438 1.14844 1 0.890625 1 0.585938C1 0.257812 1.23438 0.0234375 1.5625 0.0234375H7.1875V0Z"
             fill="currentColor"
            />
           </svg>
          </div>
         </a>
        </div>
       </div>
       <div className="lg:col-span-4 grid sm:grid-cols-3 lg:grid-cols-6 gap-x-s32 gap-y-s56">
        {menuLinks.map((menu, index) => (
         <div className="" key={index}>
          <div className="py-s12 px-s16">
           <Link to={menu.link} className="text-p_14 text-c-neutral-200">
            {menu.title}
           </Link>
          </div>
         </div>
        ))}
       </div>
      </div>
      <div className="text-center pt-s100">
       <div className="text-[0.88rem] text-c-neutral-500">
        © <span className="year-change">2024</span> Zalva Network. All rights
        reserved
       </div>
      </div>
      <div className="h-s300 relative">
       <img
        src="/images/bg-visual.webp"
        alt="bg-visual"
        width={1920}
        className="w-[40rem] object-contain max-sm:top-[45%] max-md:top-[23%] rotate-[-169deg] absolute left-1/2 top-0 -translate-x-1/2"
        height={1080}
       />
      </div>
     </div>
    </footer>
   </div>
  </div>
 );
}
