import React from "react";
import { cn } from "../../utils/helpers";

export default function Partner({ item, className }) {
 return (
  <div
   className={cn(
    "p-s4 border border-c-neutral-850 rounded-2xl h-full min-h-[15rem] flex flex-col justify-between self-stretch transition-all hover:border-c-neutral-700 hover:bg-c-neutral-900-50",
    className
   )}
  >
   <div className="p-s16 flex-1 rounded-2xl flex justify-center items-center">
    <img
     loading="lazy"
     alt="Transak"
     src={item}
     className="partner_logo max-sm:w-[70%]"
    />
   </div>
  </div>
 );
}
