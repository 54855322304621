import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBuyPresale } from "fuhi-web3ts";
import { useMemo } from "react";

const methods = [
 {
  name: "BNB",
  id: "bnb",
  logo: "/icons/bnb.png",
 },
 {
  name: "USDT",
  id: "usdt",
  logo: "/icons/usdt.png",
 },
];
export const SelectMethod = () => {
 const { method: selectedMethod, setMethod } = useBuyPresale();
 const method = useMemo(() => {
  return methods.find((f) => f.id === selectedMethod);
 }, [selectedMethod]);
 return (
  <button
   onClick={() => setMethod(selectedMethod === "bnb" ? "usdt" : "bnb")}
   className={`flex gap-1 text-white items-center`}
  >
   <img src={method.logo} alt="" className="h-8" />
   <span className="text-sm">{method.name}</span>
   <FontAwesomeIcon icon={faChevronDown} className="text-xs" />
  </button>
 );
};
