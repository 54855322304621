"use client";

import { useData } from "fuhi-web3ts";
import React from "react";
import Partner from "../../components/cards/Partner";
import Slider from "../../components/layout/Slider";

export default function FeaturedPartner() {
 const { data: items } = useData({
  path: "/data/partners.json",
  default: [],
 });
 return (
  <Slider
   title="Featured Partner"
   className="!overflow-visible"
   items={items}
   renderItem={(item) => <Partner item={item} />}
  />
 );
}
