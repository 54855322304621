import { useBuyPresale } from "fuhi-web3ts";
import { useMemo } from "react";

export const useBuyMethod = () => {
 const { method, setMethod } = useBuyPresale();
 const logo = useMemo(() => {
  if (method === "bnb") return "/icons/bnb.png";
  return "/icons/usdt.png";
 }, [method]);
 const methodName = useMemo(() => {
  if (method === "bnb") return "BNB";
  return "USDT";
 }, [method]);
 return {
  method,
  setMethod,
  logo,
  methodName,
 };
};
