import React from "react";
import { LinkWhitepaper } from "../../components/LinkCollection";
import Button from "../../components/ui/Button";

export default function VoteFuture() {
 return (
  <section className="section mt-s60 mb-s200">
   <img
    src="/cdn/65b12b9aa8fe7d6f25c09682_stacking-transition-visual.webp"
    loading="lazy"
    alt=""
    class="img_stacking-transition"
   />
   <div className="container py-s100">
    <div className="flex gap-s80 max-md:flex-col">
     <h2 className="text-[3rem] font-medium lg:text-[5.25rem]">
      Enhance Your Benefits with Zalva Network
     </h2>
     <div className="text-p_21 text-c-neutral-500 leading-normal md:min-w[350px] md:max-w-[30%]">
      Users who participate in staking can have special benefits such as the
      right to participate in network governance voting or access to exclusive
      features.{" "}
     </div>
    </div>
   </div>
   <div className="bg-c-black-900 rounded-[2.5rem] overflow-hidden relative">
    <div className="container_bg">
     <img
      src="/cdn/65b12f19901a0e1ac968fe8a_stakes-cta-img.webp"
      loading="lazy"
      alt=""
      className="absolute w-[120%] h-full object-cover max-w-none max-h-none top-[-35%] left-[-10%] z-[1] pointer-events-none right-0 bottom-0"
     />
    </div>
    <div className="container relative z-[1] pt-s200 pb-s64">
     <div className="md:h-s80" />
     <div className="flex max-md:flex-col max-md:text-center max-md:items-center gap-s32 justify-between items-end">
      <div>
       <div className="max-w-[54ch]">
        <h2 className="max-md:text-[2.5rem] text-[3.5rem] font-medium tracking-[-.01em]">
         Protect the chain, earn rewards
        </h2>
        <div className="text-p_21 text-c-neutral-400 mt-s16">
         Cyber ​​security solutions and get rewarded accordingly.
        </div>
       </div>
      </div>
      <div className="flex flex-wrap gap-s16">
       <Button
        url="/staking"
        color="teal"
        className="max-sm:w-full font-medium !px-s20"
       >
        Stake ZAL
        <div className="w-s20 h-s20 flex justify-center items-center">
         <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
         >
          <path
           d="M24 1.5C24 6.84375 20.0156 11.25 14.8594 11.9062C14.5312 9.42188 13.4531 7.17188 11.8125 5.39062C13.5938 2.20312 17.0625 0 21 0H22.5C23.2969 0 24 0.703125 24 1.5ZM0 4.5C0 3.70312 0.65625 3 1.5 3H3C8.76562 3 13.5 7.73438 13.5 13.5V15V22.5C13.5 23.3438 12.7969 24 12 24C11.1562 24 10.5 23.3438 10.5 22.5V15C4.6875 15 0 10.3125 0 4.5Z"
           fill="currentColor"
          />
         </svg>
        </div>
       </Button>
       <LinkWhitepaper>
        <Button color="neutral" className="max-sm:w-full font-medium !px-s16">
         Read Whitepaper
         <div className="w-s20 h-s20 flex justify-center items-center">
          <svg
           width="100%"
           height="100%"
           viewBox="0 0 24 22"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
          >
           <path
            d="M3 0.5H21C22.6406 0.5 24 1.85938 24 3.5V6.5C24 8.1875 22.6406 9.5 21 9.5H3C1.3125 9.5 0 8.1875 0 6.5V3.5C0 1.85938 1.3125 0.5 3 0.5ZM16.125 3.875C15.4688 3.875 15 4.39062 15 5C15 5.65625 15.4688 6.125 16.125 6.125C16.7344 6.125 17.25 5.65625 17.25 5C17.25 4.39062 16.7344 3.875 16.125 3.875ZM18.375 5C18.375 5.65625 18.8438 6.125 19.5 6.125C20.1094 6.125 20.625 5.65625 20.625 5C20.625 4.39062 20.1094 3.875 19.5 3.875C18.8438 3.875 18.375 4.39062 18.375 5ZM3 12.5H21C22.6406 12.5 24 13.8594 24 15.5V18.5C24 20.1875 22.6406 21.5 21 21.5H3C1.3125 21.5 0 20.1875 0 18.5V15.5C0 13.8594 1.3125 12.5 3 12.5ZM16.125 15.875C15.4688 15.875 15 16.3906 15 17C15 17.6562 15.4688 18.125 16.125 18.125C16.7344 18.125 17.25 17.6562 17.25 17C17.25 16.3906 16.7344 15.875 16.125 15.875ZM18.75 17C18.75 17.6562 19.2188 18.125 19.875 18.125C20.4844 18.125 21 17.6562 21 17C21 16.3906 20.4844 15.875 19.875 15.875C19.2188 15.875 18.75 16.3906 18.75 17Z"
            fill="currentColor"
           />
          </svg>
         </div>
        </Button>
       </LinkWhitepaper>
      </div>
     </div>
    </div>
   </div>
  </section>
 );
}
