import React from "react";

export default function FAQCard({ item, className }) {
  return (
    <div
      itemType="https://schema.org/Question"
      itemScope="itemscope"
      itemProp="mainEntity"
      className="faq_item"
    >
      <div>
        <h3 className="max-md:text-[2rem] text-[2.5rem] font-medium leading-[1.2]" itemProp="name">{item.title}</h3>
      </div>
      <div
        itemType="https://schema.org/Answer"
        itemScope="itemscope"
        itemProp="acceptedAnswer"
      >
        <div itemProp="text" className="rich-text text-c-neutral-400">
          {item.content}
        </div>
      </div>
    </div>
  );
}
