import React from "react";
import FeatureCard from "../../components/cards/FeatureCard";

const items = [
 {
  title: "Transaction Fees",
  description:
   "With our optimized fee structure, users can make transactions quickly and cost-effectively, making ZAL an important asset for anyone interacting with the network.",

  img: "/cdn/65b62fda1a60e7af2f5d7261_3d-abstract-emerald-green-crystal-3.webp",
 },
 {
  title: "Staking and Rewards",
  description:
   "By staking ZAL Token, users contribute to the overall stability and security of Zalva, while enjoying attractive returns on their investment.",

  img: "/cdn/65b62fdc16e06158e256cd1d_3d-abstract-emerald-green-crystal-2.webp",
 },
 {
  title: "Incentives for Developers",
  description:
   "ZAL Token is used to incentivize developers to build and deploy decentralized applications on Zalva Network.",

  img: "/cdn/65b62fdb67fd9e5920a3b394_3d-abstract-emerald-green-crystal-1.webp",
 },
];

export default function GetFamiliar() {
 return (
  <section className="section pt-s100">
   <div className="container">
    <div className="flex flex-col justify-center text-center items-center">
     <div className="max-w-[100ch]">
      <h2 className="text-[3rem] font-medium lg:text-[5.25rem]">
       Key Features of ZAL Token
      </h2>
     </div>
     <div className="max-w-[60ch] mt-s16">
      <div className="text-p_21 text-c-neutral-500 leading-normal">
       As the lifeblood of the Zalva Network, the ZAL Token plays a central role
       in driving transactions, securing the network, and enabling many
       decentralized applications (dApps).
      </div>
     </div>
    </div>
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-s16 gap-y-s56 mt-s100">
     {items.map((item, index) => (
      <FeatureCard key={index} item={item} className="overflow-visible" />
     ))}
    </div>
   </div>
  </section>
 );
}
