import React from "react";
import { cn } from "../../utils/helpers";
import Button from "../ui/Button";

export default function SideFeature({ item, className }) {
 return (
  <section className={cn("", className)}>
   <div className="container py-s200 max-lg:py-s100">
    <div className="grid lg:grid-cols-2 gap-s32">
     <div
      className={cn("lg:pr-s80 max-md:order-2", item.reverse && "lg:order-2")}
     >
      <h3 className="text-[2rem]">{item.title}</h3>
      <p className="text-p_21 text-c-neutral-500 leading-normal mt-s24 mb-s48">
       {item.description}
      </p>
      {item.ctas && item.ctas.length > 0 && (
       <div className="flex max-sm:flex-col gap-s16">
        {item.ctas.map((cta, index) => (
         <Button
          key={index}
          url={cta.href}
          target="_blank"
          className=""
          color={cta.type}
         >
          <div className="p_16">{cta.text}</div>
          <div className="w-s14 h-s14 flex justify-center items-center">
           <svg
            width="100%"
            height="100%"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d="M14.75 0.5C15.125 0.5 15.5 0.875 15.5 1.25V11.75C15.5 12.1719 15.125 12.5 14.75 12.5C14.3281 12.5 14 12.1719 14 11.75V3.07812L1.76562 15.3125C1.48438 15.5938 0.96875 15.5938 0.6875 15.3125C0.40625 15.0312 0.40625 14.5156 0.6875 14.2344L12.9219 2H4.25C3.82812 2 3.5 1.67188 3.5 1.25C3.5 0.875 3.82812 0.5 4.25 0.5H14.75Z"
             fill="currentColor"
            />
           </svg>
          </div>
         </Button>
        ))}
       </div>
      )}
     </div>
     <div className="relative flex justify-center items-center">
      <img
       src={item.img}
       alt=""
       className={cn(
        "max-md:w-[120%] max-lg:w-[170%] w-[130%] aspect-square max-w-none lg:absolute object-contain",
        item.reverse ? "right-0" : "left-0"
       )}
      />
      <div className="show_mobile-l">
       <div className="space_square" />
      </div>
     </div>
    </div>
   </div>
  </section>
 );
}
