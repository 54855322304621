import React from "react";
import FAQList from "../../components/layout/FAQList";

const items = [
 {
  title: "How is privacy guaranteed?",
  content: (
   <>
    <p>
     Zalva Network uses Zero-Knowledge Proofs (ZK-Proofs) to verify transactions
     without revealing any sensitive information. This ensures user privacy is
     maintained while still confirming the validity of transactions on the
     network.
    </p>
   </>
  ),
 },
 {
  title: "How does Zalva prevent unauthorized access?",
  content: (
   <>
    <p>
     Zalva Network uses strong encryption methods to protect data transmitted
     over the network. This encryption prevents unauthorized access, ensuring
     user information is secure and safe.
    </p>
   </>
  ),
 },
 {
  title: "What are the remedies against concentrated attacks?",
  content: (
   <>
    <p>
     Zalva Network is built on a decentralized architecture, with no single
     entity controlling the network. This decentralized security model reduces
     the risk of centralized attacks, making the network more resilient and
     secure.
    </p>
   </>
  ),
 },
 {
  title: "How does Zalva handle potential vulnerabilities in the network?",
  content: (
   <>
    <p>
     Zalva Network proactively addresses potential vulnerabilities through
     continuous monitoring and regular updates. The network also incentivizes
     the community to report bugs, offering rewards for identifying and fixing
     security issues, ensuring the network remains secure and resilient.
    </p>
   </>
  ),
 },
 {
  title: "How does Zalva ensure the security of cross-chain transactions?",
  content: (
   <>
    <p>
     Zalva Network secures cross-chain transactions through advanced bridging
     technology, including multi-signature protocols and time-lock contracts.
     These measures ensure that assets and data transferred between different
     blockchains are protected from tampering and unauthorized access.
    </p>
   </>
  ),
 },
 {
  title: "Can users customize their security settings on Zalva Network?",
  content: (
   <>
    <p>
     Yes, Zalva Network allows users to customize their security and privacy
     settings. Users can choose the level of privacy for their transactions,
     whether they prefer completely transparent or more private interactions,
     allowing them to control their privacy preferences.
    </p>
   </>
  ),
 },
];

export default function FAQs() {
 return (
  <FAQList
   title="Frequently Asked Questions"
   subtitle="Learn about how Zalva Network is secure"
   items={items}
  />
 );
}
